import { gql } from '@apollo/client';

export const GET_DIAGNOSTICS_QUERY = gql`
  query getDiagnostics($doRefresh: Boolean) {
    getDiagnostics(doRefresh: $doRefresh) {
      name
      isOK
    }
  }
`;

export const GET_TROUBLESHOOTING = gql`
  query GetTroubleshooting(
    $assessments: [AssessmentName!]
    $doRefresh: Boolean!
    $isAccountActive: Boolean!
  ) {
    troubleshooting(
      assessments: $assessments
      doRefresh: $doRefresh
      isAccountActive: $isAccountActive
    ) @client {
      lastRan
      healthChecks {
        ServiceConfiguration {
          healthStatus
          type
          fix
        }
        ViasatNetwork {
          healthStatus
          type
          fix
        }
        DishAlignment {
          healthStatus
          type
          fix
        }
        DishToModemConnection {
          healthStatus
          type
          fix
        }
        WifiModem {
          healthStatus
          type
          fix
        }
        Billing {
          healthStatus
          type
          fix
        }
        DataUsage {
          healthStatus
          type
          fix
        }
      }
      houseOK
      networkOK
      errorToFix
    }
  }
`;

export const REBOOT_MODEM = gql`
  mutation rebootModem {
    rebootModem {
      rebootSuccess
    }
  }
`;

export const GET_MODEM_STATUS = gql`
  query getModemStatus($refetchData: Boolean) {
    getModemStatus(refetchData: $refetchData) {
      isOnline
    }
  }
`;
