import React from 'react';
import { View } from 'react-native';
import TroubleshootingNotAllowed from './TroubleshootingNotAllowed';
import { QuickHelpLinks } from './QuickHelpLinks';
import { TroubleshootingSummary } from './TroubleshootingSummary';
import { TSLoadingAnimation } from './TSLoadingAnimation';
import { useAccountStatus } from '@mfe/legacy/mv/utils';

import { useTroubleshootContext } from '../TroubleshootContext';
import BackgroundGradientSVG from './TabNavHeader';

export const TroubleshootingHome = (): JSX.Element => {
  const { isAccountDisconnected, isAccountSuspended } = useAccountStatus();
  const { loading, error } = useTroubleshootContext();
  const height = error ? 470 : 550;

  if (isAccountSuspended || isAccountDisconnected) {
    return (
      <TroubleshootingNotAllowed
        testID="ts-not-allowed"
        isSuspended={isAccountSuspended}
        isDisconnected={isAccountDisconnected}
      />
    );
  }

  return (
    <div
      data-cy="helpScreen"
      style={{ backgroundColor: 'white', height: '100%', overflow: 'hidden' }}
    >
      <BackgroundGradientSVG
        width="100vw"
        height="200vh"
        viewBox="0 512 320 365"
        style={{
          position: 'absolute',
          inset: 0,
          zIndex: 0,
          height: '100vh',
          overflow: 'hidden',
        }}
      />
      <View style={{ height: loading ? height : undefined }}>
        {loading ? <TSLoadingAnimation /> : <TroubleshootingSummary />}
      </View>
      <QuickHelpLinks />
    </div>
  );
};
