import React from 'react';
import Svg, { Path, Circle, G } from 'react-native-svg';

const CircleErrorExclamation = (): JSX.Element => (
  <Svg width={34} height={34}>
    <G fill="none" fillRule="evenodd">
      <Circle fill="#fff" cx={17} cy={17} r={17} />
      <Circle
        cx={14.6}
        cy={14.6}
        r={14.6}
        transform="translate(2.9 1.9)"
        fill="#a82200"
      />
      <G fill="#fff" fillRule="nonzero">
        <Path d="M16 20.8h3.4V24H16v-3.2zM16 8h3.4v9.6H16V8z" />
      </G>
    </G>
  </Svg>
);

export default CircleErrorExclamation;
