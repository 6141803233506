import { useScreenResolution } from '@mfe/shared/util';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { Button, Surface, Txt } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styles from './styles.module.scss';

export const NoAddOns = () => {
  const { t } = useTranslation(['Profile', 'Overview']);
  const dispatch = useDispatch();

  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;
  return (
    <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
      <div className={styles['header']}>
        <Txt variant="heading5">{t('Profile:addOns.title')}</Txt>
      </div>
      <div className={styles['no-add-ons-container']}>
        <Txt variant="bodySmallRegular" color="subtle">
          {t('Profile:addOns.noAddOns')}
        </Txt>
        <Button
          variant="secondary"
          icon={OpenInNew}
          iconPos="right"
          onClick={(e) => {
            e.preventDefault();
            dispatch(openInNewTab(t('Overview:addOns.shopAddonsLink')));
          }}
        >
          {t('Overview:addOns.shopAddons')}
        </Button>
      </div>
    </Surface>
  );
};
