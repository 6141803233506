import { Badge, StandAloneLink, Icon, Surface, Txt, colors } from '@vst/beam';
import {
  SecurityOutlined,
  ConstructionOutlined,
  PhoneOutlined,
  WorkOutlined,
  LanguageOutlined,
  OnDemandVideoOutlined,
  OpenInNew,
} from '@vst/beam-icons/icons';
import styles from './styles.module.scss';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType } from '@mfe/shared/schema-types';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';

type Props = {
  handleManagePlan: () => void;
};

export const AddOnsTypes = ({ handleManagePlan }: Props): JSX.Element => {
  const { t } = useTranslation('Overview', { keyPrefix: 'addOns' });
  const dispatch = useDispatch();
  const { hasStreamOnActive } = useSelector(selectStreamOnPromo);
  const {
    addOns: { hasEasyCare, hasOfficeHours, hasShield, hasStaticIP, hasVoice },
  } = useSelector(selectAddOns);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const activeAddons = [
    hasStreamOnActive,
    hasEasyCare,
    hasOfficeHours,
    hasShield,
    hasStaticIP,
    hasVoice,
  ].filter(Boolean).length;
  const containerClass = `${styles['addons-container']} ${
    activeAddons > 1 ? '' : styles['single-column']
  }`;

  return (
    <Surface className={containerClass}>
      {hasEasyCare && (
        <div className={styles['addons-section']} data-cy="easy-care">
          <div>
            <Surface
              style={{ background: colors['green'][100] }}
              className={styles['icon-container']}
            >
              <Icon size="24" icon={ConstructionOutlined} color={'green_400'} />
            </Surface>
            <Txt variant="bodyLargeBold">{t('easyCare')}</Txt>
            <Badge state="positive" label={t('subscribed')} showIcon={false} />
          </div>
        </div>
      )}
      {hasVoice && (
        <div className={styles['addons-section']} data-cy="voice">
          <div>
            <Surface
              style={{ background: colors['blue'][100] }}
              className={styles['icon-container']}
            >
              <Icon size={'24'} icon={PhoneOutlined} color={'blue_600'} />
            </Surface>
            <Txt variant="bodyLargeBold" style={{ whiteSpace: 'nowrap' }}>
              {t('viasatVoice')}
            </Txt>
            <Badge state="positive" label={t('subscribed')} showIcon={false} />
          </div>
          <StandAloneLink
            data-cy="manage-link"
            variant="secondary"
            icon={OpenInNew}
            iconPos="right"
            href=""
            onClick={(e) => {
              e.preventDefault();
              dispatch(openInNewTab(t('alianzaLink')));
            }}
          >
            {t('manage')}
          </StandAloneLink>
        </div>
      )}
      {hasOfficeHours && (
        <div className={styles['addons-section']} data-cy="office-hours">
          <div>
            <Surface
              style={{ background: colors['pink'][100] }}
              className={styles['icon-container']}
            >
              <Icon size={'24'} icon={WorkOutlined} color={'pink_500'} />
            </Surface>
            <Txt variant="bodyLargeBold" style={{ whiteSpace: 'nowrap' }}>
              {t('officeHours')}
            </Txt>
            <Badge
              data-cy="office-hours-badge"
              state="positive"
              label={
                accountType === AccountType.Residential
                  ? t('subscribed')
                  : t('included')
              }
              showIcon={false}
            />
          </div>
        </div>
      )}
      {hasShield && (
        <div className={styles['addons-section']} data-cy="shield">
          <div>
            <Surface
              style={{ background: colors['warm'][100] }}
              className={styles['icon-container']}
            >
              <Icon size={'24'} icon={SecurityOutlined} color={'warm_500'} />
            </Surface>
            <Txt variant="bodyLargeBold">{t('shieldPremium')}</Txt>
            <Badge state="positive" label={t('subscribed')} showIcon={false} />
          </div>
        </div>
      )}
      {hasStaticIP && (
        <div className={styles['addons-section']} data-cy="static-ip">
          <div>
            <Surface
              style={{ background: colors['gray'][200] }}
              className={styles['icon-container']}
            >
              <Icon size={'24'} icon={LanguageOutlined} color={'gray_700'} />
            </Surface>
            <Txt variant="bodyLargeBold">{t('staticIp')}</Txt>
            <Badge state="positive" label={t('subscribed')} showIcon={false} />
          </div>
        </div>
      )}
      {hasStreamOnActive && (
        <div className={styles['addons-section']} data-cy="stream">
          <div>
            <Surface
              style={{ background: colors['violet'][100] }}
              className={styles['icon-container']}
            >
              <Icon
                size={'24'}
                icon={OnDemandVideoOutlined}
                color={'violet_500'}
              />
            </Surface>
            <Txt variant="bodyLargeBold" style={{ whiteSpace: 'nowrap' }}>
              {t('viasatStream')}
            </Txt>
            <Badge state="positive" label={t('subscribed')} showIcon={false} />
          </div>
        </div>
      )}
    </Surface>
  );
};
