import React from 'react';
import Svg, {
  G,
  Polygon,
  LinearGradient,
  Stop,
  Defs,
  SvgProps,
} from 'react-native-svg';

const TroubleshootingErrorBg = ({
  width = 320,
  height = 551,
  ...otherProps
}: SvgProps): JSX.Element => (
  <Svg width={width} height={height} viewBox="0 0 320 551" {...otherProps}>
    <Defs>
      <LinearGradient
        x1="60.6331376%"
        y1="98.6603088%"
        x2="66.8948879%"
        y2="37.6326919%"
        id="TroubleShootingErrorBg-1"
      >
        <Stop stopColor="#FFD2C7" offset="0%" />
        <Stop stopColor="#86090F" offset="100%" />
      </LinearGradient>
      <LinearGradient
        x1="39.8800529%"
        y1="185.671658%"
        x2="62.910317%"
        y2="30.7906539%"
        id="TroubleShootingErrorBg-2"
      >
        <Stop stopColor="#FFBDAC" offset="0%" />
        <Stop stopColor="#A82200" offset="100%" />
      </LinearGradient>
    </Defs>
    <G stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <Polygon
        fill="url(#TroubleShootingErrorBg-1)"
        transform="translate(160.000000, 275.750000) scale(1, 1) translate(-160.000000, -275.750000) "
        points="320 0.5 320 551 0 382.357811 0 122.5"
      />
      <Polygon
        fill="url(#TroubleShootingErrorBg-2)"
        opacity="0.604468936"
        transform="translate(160.000000, 269.000000) scale(1, 1) translate(-160.000000, -269.000000) "
        points="320 485 9.09494702e-13 406.25009 9.09494702e-13 97.6 320 53"
      />
    </G>
  </Svg>
);

export default TroubleshootingErrorBg;
