import {
  GetTransactionIdInput,
  Locale,
  Mutation,
  Query,
} from '@mfe/shared/schema-types';
import { all, call, put, select, spawn, takeLatest } from 'redux-saga/effects';
import { AnalyticsEventNames, trackBillingEvent } from '../analytics';
import { refetchBillingInfo, selectBillingInfo } from '../billingInfo';
import { selectConfig } from '@mfe/shared/redux/config';
import { graphqlMutation, graphqlQuery } from '@mfe/shared/redux/graphql';
import {
  retrieveTransactionId,
  initiatePayment,
  selectPayments,
  setPayments,
  setTransactionIdError,
  setLoading,
  PaymentAction,
} from './paymentsSlice';
import { selectLocale } from '../locale';
import { Alert, removeAlert, selectAlerts } from '../alerts';
import i18n from '@mfe/services/translations-service';
import { selectUser } from '../auth';
import {
  saveAlertNameInStorage,
  AUTOPAY_PREAUTH_AMOUNT,
} from '@mfe/shared/util';
import { GET_TRANSACTION_ID, MAKE_PAYMENT } from './queries-and-mutations';

export function* getTransactionId({ payload }: PaymentAction) {
  const {
    locale: { userLocale },
  }: ReturnType<typeof selectLocale> = yield select(selectLocale);

  const {
    billingInfo: { billingAccount },
  }: ReturnType<typeof selectBillingInfo> = yield select(selectBillingInfo);

  const { vppUrl }: ReturnType<typeof selectConfig> = yield select(
    selectConfig
  );

  const { txnAmount, paymentMethodType }: ReturnType<typeof selectPayments> =
    yield select(selectPayments);

  const txnValue = () => {
    if (userLocale === Locale.PtBr) return payload?.amountOwedOnInvoice?.value;

    // Reference for the AUTOPAY_PREAUTH_AMOUNT value https://jira.viasat.com/browse/VGFB-591
    return paymentMethodType === 'credit_card' &&
      txnAmount === 0 &&
      !payload?.invoiceNumber
      ? AUTOPAY_PREAUTH_AMOUNT
      : txnAmount;
  };

  const input: GetTransactionIdInput = {
    userAgent: window.navigator.userAgent,
    txnAmount: {
      alphabeticCode: billingAccount?.balance?.alphabeticCode ?? 'USD',
      value: txnValue(),
    },
    invoiceNumber: payload?.invoiceNumber,
    transactionType: payload?.invoiceNumber ? 'OTP' : undefined,
  };

  try {
    yield put(setLoading(true));
    yield put(setPayments({ frameReady: false }));
    const data: Query = yield call(graphqlQuery, {
      query: GET_TRANSACTION_ID,
      variables: { input },
      fetchPolicy: 'no-cache',
    });

    const transactionId = data?.getTransactionId?.transactionId;
    const url = transactionId ? `${vppUrl}?id=${transactionId}` : '';
    yield put(setPayments({ url }));
  } catch (error) {
    const getTransactionIdErrorCode = '[getTransactionId] Failed to fetch';
    const getTransactionIdErrorMessage = 'Failed to fetch transaction ID';
    const payload = {
      error: {
        code: getTransactionIdErrorCode,
        message: getTransactionIdErrorMessage,
      },
    };

    yield put(setTransactionIdError(payload));
    yield put(setLoading(false));
  }
}

export function* makePayment({ payload }: PaymentAction) {
  const {
    billingInfo: { billingAccount, autoPay },
  }: ReturnType<typeof selectBillingInfo> = yield select(selectBillingInfo);

  const { txnAmount }: ReturnType<typeof selectPayments> = yield select(
    selectPayments
  );

  const input = {
    userAgent: window.navigator.userAgent,
    txnAmount: {
      alphabeticCode: billingAccount?.balance?.alphabeticCode ?? 'USD',
      value: txnAmount,
    },
    invoiceNumber: payload?.invoiceNumber,
  };

  const data: Mutation = yield call(graphqlMutation, {
    mutation: MAKE_PAYMENT,
    variables: { input },
    fetchPolicy: 'no-cache',
  });

  const paymentDetails = {
    payment_type: 'recurring',
    payment_amount_type:
      billingAccount?.balance?.value === txnAmount ? 'full' : 'partial',
    agent_fee: 'not_applicable',
    payment_value: txnAmount,
    payment_currency: billingAccount?.balance?.alphabeticCode ?? '',
    payment_method_type: autoPay?.paymentMethod,
  };

  if (data?.makePayment?.success) {
    yield put(
      trackBillingEvent({
        eventName: AnalyticsEventNames.MakePaymentSuccessful,
        version: '1-0-1',
        data: paymentDetails,
      })
    );

    yield put(
      setPayments({ success: true, paymentMethod: autoPay ?? undefined })
    );

    const alertToBeRemoved: Alert | undefined = yield call(getAlertToRemove);
    if (alertToBeRemoved) {
      yield put(removeAlert(alertToBeRemoved.title));
      yield call(saveAlertNameInStorage, alertToBeRemoved.title);
    }

    yield put(refetchBillingInfo());
  } else {
    const error = data?.makePayment?.error ?? undefined;

    const paymentDetailsFailure = {
      ...paymentDetails,
      failure_reason: error?.message ?? 'Payment Failed.',
    };

    yield put(
      trackBillingEvent({
        eventName: AnalyticsEventNames.MakePaymentFailed,
        version: '1-0-1',
        data: paymentDetailsFailure,
      })
    );

    yield put(setPayments({ error, submit: false }));
  }
}

function* getAlertToRemove() {
  const {
    user: { isACPSuspended },
  }: ReturnType<typeof selectUser> = yield select(selectUser);

  const { alerts }: ReturnType<typeof selectAlerts> = yield select(
    selectAlerts
  );

  const suspensionType = isACPSuspended ? 'acp_suspended' : 'suspended';

  const alertTitles = [
    i18n.t(`Alerts:AccountStatus.${suspensionType}.title`),
    i18n.t(`Alerts:AccountStatus.disconnected.title`),
    i18n.t('Alerts:AccountStatus.pastDue.title'),
    i18n.t('Alerts:AccountStatus.softSuspend.title'),
    i18n.t('Alerts:AccountStatus.preSoftSuspend.title'),
  ];

  const alertToBeRemoved = alerts.find((alert) =>
    alertTitles.includes(alert.title || '')
  );

  return alertToBeRemoved;
}

export function* watchPayments() {
  yield spawn(function* () {
    yield all([
      takeLatest(retrieveTransactionId.type, getTransactionId),
      takeLatest(initiatePayment.type, makePayment),
    ]);
  });
}
