import React, { Suspense } from 'react';
import FullPageLoading from '../../views/FullPageLoading';

const LazyTroubleshootingSwitch = React.lazy(
  () => import('./LazySwitches/TroubleshootingSwitch')
);

export const TroubleshootSwitch = (): JSX.Element => (
  <Suspense fallback={<FullPageLoading />}>
    <LazyTroubleshootingSwitch />
  </Suspense>
);
