import React from 'react';
import { SafeAreaView, ScrollView, View, StyleSheet } from 'react-native';
import {
  Color,
  Loading,
  Text,
  useDimensionContext,
} from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';

const styles = StyleSheet.create({
  loadingText: {
    marginTop: 20,
  },
  nativeLoadingContainer: {
    alignItems: 'center',
    paddingTop: 70,
  },
  webLoadingContainer: {
    alignItems: 'center',
    marginTop: 200,
  },
});

const DrawerLoadingState = ({
  testID = 'loadingDrawer',
}: {
  testID?: string;
}): JSX.Element => {
  const { t } = useTranslation('Common');
  const { isMobile } = useDimensionContext();

  return (
    <SafeAreaView testID={testID}>
      <ScrollView>
        <View
          style={
            isMobile
              ? styles.nativeLoadingContainer
              : styles.webLoadingContainer
          }
        >
          <Loading
            size={120}
            color={Color.teal500}
            strokeWidth={2}
            testID="loadingIcon"
          />
          <Text italic style={styles.loadingText} testID="loadingBodyText">
            {t('Loading')}
          </Text>
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default DrawerLoadingState;
