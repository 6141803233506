import React, { useState, useEffect, useContext } from 'react';
import { AssessmentName } from '@viasat/res-apps-lib/build/types/mv';
import { useNavigate } from '@mfe/legacy/andromeda';
import { TroubleshootLink } from '@mfe/legacy/mv/utils/Navigation';
import { useTrackScreenFunction } from '@mfe/legacy/mv/containers';

import { useTroubleshootContext } from '../TroubleshootContext';
import { FixScreens } from '../GraphQLResolver';
import { createFixNavigator, doAssessmentsPass } from '../utils';

const FixNavigatorContext = React.createContext<{
  currentFixScreen: FixScreens | null;
  setCurrentFixScreen: (wizardState: FixScreens) => void;
  goToNextFix: () => void;
  checkModem: (_: {
    onModemHealthy: () => void;
    onModemStillUnhealthy: () => void;
  }) => Promise<void>;
}>({
  currentFixScreen: null,
  setCurrentFixScreen: (wizardState: FixScreens) => {
    /* empty */
  },
  goToNextFix: () => {
    /* empty */
  },
  checkModem: async ({ onModemHealthy, onModemStillUnhealthy }) => {
    /* empty */
  },
});

export const FixNavigatorProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const goTo = useNavigate();
  const trackScreen = useTrackScreenFunction();

  const [currentFixScreen, setCurrentFixScreen] = useState<FixScreens | null>(
    null
  );

  const { data, refetch } = useTroubleshootContext();
  const healthChecks = data?.troubleshooting?.healthChecks ?? null;
  const [fixNavigator, setFixNavigator] = useState(
    createFixNavigator(healthChecks)
  );

  useEffect(() => {
    setFixNavigator(createFixNavigator(healthChecks));
  }, [healthChecks]);

  // on initial render, make sure the wizard is able set to something
  useEffect((): void => {
    if (currentFixScreen) return;

    const wizardResult = fixNavigator.next();
    setCurrentFixScreen(
      wizardResult.done ? FixScreens.Configuration : wizardResult.value
    );
  }, []);

  useEffect((): void => {
    const stateForAnalytics = `${currentFixScreen}-Troubleshooting`;
    trackScreen(stateForAnalytics, stateForAnalytics);
  }, [currentFixScreen, trackScreen]);

  const goToNextFix = (): void => {
    const { value, done } = fixNavigator.next();

    if (done) {
      goTo(TroubleshootLink.DiagnosticResults);
    } else {
      setCurrentFixScreen(value);
    }
  };

  const checkModem = async ({
    onModemHealthy,
    onModemStillUnhealthy,
  }: {
    onModemHealthy: () => void;
    onModemStillUnhealthy: () => void;
  }): Promise<void> => {
    // These are the assessments that need to be run associated with modem issues
    const modemCheckAssessments = [
      AssessmentName.WifiModem,
      AssessmentName.DishToModemConnection,
    ];

    const wasRefreshSuccessful = await refetch({});
    if (!wasRefreshSuccessful) {
      setCurrentFixScreen(FixScreens.HardRebootFailure);
      throw new Error('Unable to run diagnostic');
    }

    if (doAssessmentsPass(healthChecks, modemCheckAssessments)) {
      onModemHealthy();
    } else {
      onModemStillUnhealthy();
    }
  };

  return (
    <FixNavigatorContext.Provider
      value={{
        currentFixScreen,
        setCurrentFixScreen,
        goToNextFix,
        checkModem,
      }}
    >
      {children}
    </FixNavigatorContext.Provider>
  );
};

export const useFixNavigator = () => useContext(FixNavigatorContext);
