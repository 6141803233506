import React from 'react';
import OrderSummary from '@mfe/legacy/mv/views/OrderSummary';
import { Profile } from '@mfe/legacy/mv/views/Profile';
import LiveChat from '../views/ContactUs';

export const routes = {
  OrderSummary: <OrderSummary />,
  'agent-chat': <LiveChat />,
  Profile: <Profile />,
};
