import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Color, Text, Button, useNavigate } from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';

import { TroubleshootLink } from '@mfe/legacy/mv/utils/Navigation';
import { useAccountStatus } from '@mfe/legacy/mv/utils';
import ProfileButton from '@mfe/legacy/mv/components/ProfileButton';
import Status, { StatusType } from '@mfe/legacy/mv/components/Status';

import { useTroubleshootContext } from '../TroubleshootContext';
import { getDateTimeStrings } from '../utils';
import { StatusCard } from './StatusCard';

const styles = StyleSheet.create({
  heading: {
    marginTop: 16,
    marginHorizontal: 16,
    marginBottom: 28,
    height: 137,
    justifyContent: 'space-around',
    alignContent: 'center',
  },
  buttonMargin: {
    marginTop: 28,
    marginBottom: 20,
    marginHorizontal: 24,
  },
  iconWrapper: {
    backgroundColor: Color.white,
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 18,
    width: 30,
    height: 30,
    marginLeft: 12,
  },
  lastRanWrapper: {
    flexDirection: 'row',
    alignSelf: 'center',
    alignItems: 'center',
    paddingTop: 16,
    paddingHorizontal: 20,
    marginBottom: 28,
  },
  pageContainer: {
    paddingHorizontal: 16,
  },
  statusCardWrapper: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 1,
  },
});

const StatusMessage = ({
  title,
  message,
}: {
  title: string;
  message?: string;
}) => (
  <View style={styles.heading}>
    <Text.H1 heavy white testID="ts-splash-title" style={{ lineHeight: 42 }}>
      {title}
    </Text.H1>
    {message && (
      <Text small white testID="ts-splash-message" style={{ paddingRight: 23 }}>
        {message}
      </Text>
    )}
  </View>
);

const LastRunInfo = (): JSX.Element => {
  const { i18n, t } = useTranslation(['Fix', 'Common']);
  const { data, refetch, error } = useTroubleshootContext();
  const lastRan =
    error || !data?.troubleshooting?.lastRan
      ? new Date()
      : new Date(data?.troubleshooting?.lastRan ?? '');
  const { date, time } = getDateTimeStrings(lastRan, i18n.language);

  return (
    <View style={styles.lastRanWrapper}>
      <Text small>{`${t('LastUpdated')} ${date} ${time}`}</Text>
      <TouchableOpacity
        onPress={(): void => {
          refetch();
        }}
      >
        <View style={styles.iconWrapper}>
          <Icon
            color={Color.teal500}
            size={23}
            style={{ marginTop: 2 }}
            name="refresh"
          />
        </View>
      </TouchableOpacity>
    </View>
  );
};

const SummaryPage = ({
  houseOK = false,
  networkOK = false,
  button = {
    action: () => {
      /* empty */
    },
  },
  children,
}: {
  button?: {
    action: () => void;
    text?: string;
  };
  houseOK?: boolean;
  networkOK?: boolean;
  children: React.ReactNode;
}) => {
  const { isAccountPreInstall } = useAccountStatus();

  return (
    <View testID="ts-splash-loaded" style={{ paddingTop: 23 }}>
      <ProfileButton />
      <View style={styles.pageContainer}>
        {children}
        <View style={styles.statusCardWrapper}>
          <StatusCard
            onStatusPressed={button.action}
            type={'Network'}
            hasError={!networkOK}
          />
          <View style={{ padding: 8 }} />
          <StatusCard
            onStatusPressed={button.action}
            type={'Home'}
            hasError={!houseOK}
          />
        </View>
        {!isAccountPreInstall && <LastRunInfo />}
        {button.text && (
          <Button
            fill
            testID="ts-splash-results"
            style={styles.buttonMargin}
            onPress={button.action}
          >
            {button.text}
          </Button>
        )}
      </View>
    </View>
  );
};

export const TroubleshootingSummary = (): JSX.Element | null => {
  const { isAccountPreInstall } = useAccountStatus();
  const goTo = useNavigate();
  const { t } = useTranslation('Fix');
  const { data, error, refetch } = useTroubleshootContext();
  const { houseOK, networkOK } = data?.troubleshooting ?? {
    houseOK: false,
    networkOK: false,
  };

  if (error) {
    return (
      <SummaryPage
        button={{
          action: () => {
            if (!error) {
              goTo(TroubleshootLink.DiagnosticResults, { append: true });
            }
          },
        }}
      >
        <Status
          noTitle
          style={{ marginHorizontal: 20, marginBottom: 16 }}
          type={StatusType.card}
          bodyData={t('DiagnosticResults').toLowerCase()}
          retry={(): void => {
            refetch();
          }}
        />
      </SummaryPage>
    );
  }

  if (isAccountPreInstall) {
    return (
      <SummaryPage houseOK networkOK>
        <StatusMessage
          title={t('DataUnavailableTitle')}
          message={t('DataUnavailableMessage') as string}
        />
      </SummaryPage>
    );
  }

  return (
    <SummaryPage
      houseOK={houseOK}
      networkOK={networkOK}
      button={{
        text: t('ViewResults') as string,
        action: () => {
          goTo(TroubleshootLink.DiagnosticResults, {
            append: true,
          });
        },
      }}
    >
      {houseOK && networkOK ? (
        <StatusMessage
          title={t('LooksGood')}
          message={t('NoIssues') as string}
        />
      ) : (
        <StatusMessage title={t('IssueDetected')} />
      )}
    </SummaryPage>
  );
};
