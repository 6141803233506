// To be deleted as part of https://jira.viasat.com/browse/VGFB-650
export enum TroubleshootLink {
  AgentChat = 'agent-chat',
  Troubleshooting = 'Troubleshooting',
  DiagnosticResults = 'DiagnosticResults',
  Faq = 'Faq',
  FixNavigator = 'Fix',
  Reboot = 'Reboot',
}

export enum NetworkLink {
  Reboot = 'Reboot',
  Network = 'Network',
  DiagnosticResults = 'DiagnosticResults',
}

export enum HelpLink {
  Main = 'Help',
  AgentChat = 'agent-chat',
}

export enum PayBillLink {
  PayBill = 'PayBill',
  UpdateBillCycle = 'UpdateBillCycle',
  OneTimePayment = 'OneTimePayment',
  AutoPay = 'AutoPay',
  Invoice = 'Invoice',
  SelectBillCycle = 'SelectBillCycle',
  ManageBilling = 'ManageBilling',
}

export enum PlanLink {
  Plan = 'PlanUsage',
  ChoosePlanFilter = 'ChoosePlanFilter',
}

export enum StackLink {
  ACP = 'ACP',
  LiveChat = 'LiveChat',
  OrderSummary = 'OrderSummary',
  Profile = 'Profile',
  Stream = 'Stream',
  ScheduleInstallation = 'ScheduleInstallation',
}

export enum TabLink {
  Billing = 'Billing',
}

export enum WebHome {
  ACP = 'ACP',
  OrderSummary = 'OrderSummary',
  Stream = 'Stream',
}

export enum WebRoutes {
  Error = 'Error',
  Home = 'Home',
  Main = '',
}
