import { all, call, select, takeLatest, put } from 'redux-saga/effects';
import { gql } from '@apollo/client';

import {
  getAddOnsPricesAndDiscounts as getAddOnsPricesAndDiscountsAction,
  selectAddOns,
  setAddOnsPricesAndDiscounts,
  setError,
} from './addOnsSlice';

import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';

import { waitForToken } from '../utils/utilsSagas';

export const GET_ADD_ONS_PRICES_AND_DISCOUNTS = gql`
  query getAddOnsPricesAndDiscounts($addOnsProductInstanceIds: [String]) {
    getAddOnsPricesAndDiscounts(
      addOnsProductInstanceIds: $addOnsProductInstanceIds
    ) {
      addOnName
      addOnPrice
      discountName
      discountEndDate
      discountAmount
      priceWithDiscount
    }
  }
`;

export function* fetchAddOnsPricesAndDiscounts() {
  yield call(waitForToken);

  const { addOnsProductInstanceIds } = yield select(selectAddOns);

  const apiResponse: FetchWithErrorsQuery = yield call(graphqlQueryWithErrors, {
    query: GET_ADD_ONS_PRICES_AND_DISCOUNTS,
    variables: { addOnsProductInstanceIds },
    fetchPolicy: 'no-cache',
  });

  const { data, errors, runtimeError } = apiResponse;

  if (runtimeError || errors) {
    yield put(setError(runtimeError ?? errors));
    return;
  }

  if (data?.getAddOnsPricesAndDiscounts) {
    yield put(
      setAddOnsPricesAndDiscounts(data.getAddOnsPricesAndDiscounts ?? [])
    );
  }
}

export function* watchAddOns() {
  yield all([
    takeLatest(
      getAddOnsPricesAndDiscountsAction.type,
      fetchAddOnsPricesAndDiscounts
    ),
  ]);
}
