import React from 'react';
import { Platform, StyleSheet, ViewStyle } from 'react-native';
import {
  ScreenSize,
  Color,
  Button,
  ButtonText,
  useNavigate,
} from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import { useDimensionContext } from '@mfe/legacy/andromeda';
import OpensInNewWindow from '../img/svgs/OpensInNewWindow';
import { useAccountStatus } from '../utils';
import { useDetectBrowser } from '@mfe/shared/util';
import { urls } from '../configs/url';
import packageJson from '../myversion.json';

const styles = StyleSheet.create({
  opensInNewWindow: {
    marginTop: 1,
  },
  feedbackText: {
    marginTop: 3,
    color: Color.gray800,
  },
});

const FeedbackButton = (): JSX.Element => {
  const { screenSize } = useDimensionContext();
  const goTo = useNavigate();
  const browser = useDetectBrowser();
  const { t } = useTranslation('Nav');
  const isWeb = Platform.OS === 'web';
  const { accountReference } = useAccountStatus();
  const appVersion = packageJson.version;

  const webButtonStyle: ViewStyle = {
    width: 125,
    height: 46,
    paddingLeft: 25,
    paddingRight: 10,
    marginBottom: 23,
    alignSelf:
      screenSize === ScreenSize.LG || screenSize === ScreenSize.XL
        ? 'flex-end'
        : 'auto',
  };

  const onPress = (): void => {
    const feedbackUrl = `${urls.feedbackUrl}?accountReference=${accountReference}&browser=${browser}&screenUser=FeedBack&appVersion=${appVersion}`;
    if (isWeb) {
      window.open(feedbackUrl, '_blank');
      return;
    } else {
      goTo('Feedback', {
        append: true,
      });
    }
  };

  return (
    <Button
      large
      color={Color.teal200}
      onPress={onPress}
      testID="feedbackButton"
      {...(isWeb
        ? {
            Icon: (
              <OpensInNewWindow
                size={24}
                color={Color.gray800}
                style={styles.opensInNewWindow}
              />
            ),
            IconPosition: 'left',
            IconOffset: -23,
            style: webButtonStyle,
          }
        : {})}
    >
      <ButtonText style={styles.feedbackText}>{t('Feedback')}</ButtonText>
    </Button>
  );
};

export default FeedbackButton;
