import React from 'react';
import { StyleSheet } from 'react-native';
import { ApolloQueryResult, ApolloError } from '@apollo/client';
import { errorLogger } from '../containers';
import { JSONStringifyCyclic } from '../utils';
import MainCardError from '../components/MainCardError';
import { NewOrderInfo } from '@viasat/res-apps-lib/build/types/mv';

export const commonStyles = StyleSheet.create({
  cardMarginVertical: { marginBottom: 16 },
  cardPaddingSM: { padding: 16 },
  cardPadding: { padding: 20 },
  cardHeaderSM: { padding: 8 },
  cardHeader: { padding: 12 },
  grayContainerPaddingSM: { paddingHorizontal: 24, paddingVertical: 32 },
  grayContainerPadding: { padding: 32 },
  grayContainerMargin: { marginTop: 16 },
});

export enum Label {
  AutoPay,
  PlanAndServices,
  InstallationAppointment,
}

export const getTranslation = (label: Label): string => {
  switch (label) {
    case Label.AutoPay:
      return 'AutoPay';
    case Label.PlanAndServices:
      return 'PlanAndServices';
    case Label.InstallationAppointment:
      return 'InstallationAppointment';
  }
};

interface ErrorCardProps {
  label: Label;
  error?: ApolloError;
  refetch: () => Promise<ApolloQueryResult<any>> | void;
}

export const ErrorCard = ({
  label,
  error,
  refetch,
}: ErrorCardProps): JSX.Element => {
  const title = getTranslation(label);
  const logger = errorLogger();
  logger(new Error(JSONStringifyCyclic(error)));

  return (
    <MainCardError
      testID="MainCardError"
      title={title}
      retry={(): void => {
        refetch();
      }}
    />
  );
};

export const getPlanPriceWithPromo = (
  promo: NewOrderInfo | undefined,
  plan: NewOrderInfo | null | undefined
): string | undefined => {
  if (promo) {
    const promoAmount = Number(promo?.amount?.substr(1));
    const planAmount = Number(plan?.amount?.substr(1, plan?.amount.length - 4));

    const promoApplied = (planAmount - promoAmount).toFixed(2);
    const newPlanPriceName = promoApplied.toString() + '/mo.';

    return newPlanPriceName;
  }
  return undefined;
};

export const getPromoNumOfMonths = (
  promo: NewOrderInfo | undefined
): number | undefined => {
  if (promo) {
    const indexOfFor = promo?.name.indexOf('for') as number;
    const indexOfMonth = promo?.name.indexOf('Months') as number;

    // get value in between 'for' and 'Months"
    const promoNumOfMonths = Number(
      promo?.name.slice(indexOfFor + 4, indexOfMonth)
    );

    return promoNumOfMonths;
  }
  return undefined;
};
