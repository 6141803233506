import { Surface, Txt, Button } from '@vst/beam';
import { AddOnsTypes } from './add-ons-types';
import styles from './styles.module.scss';
import { OpenInNew } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { selectStreamOnPromo } from '@mfe/to-be-migrated/redux/streamOnPromo';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';
import { AddOnFooter } from './add-ons-footer';
import { NoAddons } from './no-add-ons';
import { selectConfig } from '@mfe/shared/redux/config';

type Props = {
  handleManagePlan: () => void;
};

export const AddonUpsellCards = ({
  handleManagePlan,
}: Props): JSX.Element | null => {
  const { t } = useTranslation(['Overview', 'Global']);
  const { hasStreamOnActive, isLoading } = useSelector(selectStreamOnPromo);
  const { loading, addOns } = useSelector(selectAddOns);
  const { showAddonRedesign } = useSelector(selectConfig);

  if (!showAddonRedesign) return null;

  const hasAddOns =
    addOns.hasVoice ||
    addOns.hasEasyCare ||
    addOns.hasShield ||
    addOns.hasStaticIP ||
    addOns.hasOfficeHours ||
    hasStreamOnActive;

  if (isLoading || loading) return <Loading />;

  return (
    <Surface data-cy="addons-container" className={styles['container']}>
      <div className={styles['header']}>
        <Txt variant="bodyLargeBold">{t('Overview:addOns.title')}</Txt>
        {hasAddOns && (
          <Button
            variant="secondary"
            icon={OpenInNew}
            iconPos="right"
            onClick={(e) => {
              e.preventDefault();
              handleManagePlan();
            }}
          >
            {t('Overview:addOns.button')}
          </Button>
        )}
      </div>
      {hasAddOns ? (
        <>
          <AddOnsTypes handleManagePlan={handleManagePlan} />
          <AddOnFooter />
        </>
      ) : (
        <NoAddons />
      )}
    </Surface>
  );
};

const Loading = () => {
  return (
    <Surface data-cy="loading" className={styles['loading-container']}>
      <div className={styles['loading-title']} />
      <div className={styles['loading-addon-section']}>
        <div className={styles['icon']} />
        <div className={styles['name']} />
        <div className={styles['desc']} />
      </div>
      <div className={styles['loading-footer']} />
    </Surface>
  );
};
