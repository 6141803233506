import { selectConfig } from '@mfe/shared/redux/config';
import { Platform } from '@mfe/shared/schema-types';
import { useSelector } from 'react-redux';
import { InlineLink, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';
import { useDetectBrowser } from '@mfe/shared/util';

export const AddOnFooter = (): JSX.Element => {
  const { platform } = useSelector(selectConfig);
  const { t } = useTranslation(['Overview', 'Global']);
  const browser = useDetectBrowser();
  const showLink = platform !== Platform.Web || browser === 'MobileWeb';

  return (
    <div data-cy="add-ons-footer">
      <Txt variant="bodySmallRegular">
        {t('Overview:addOns.manageAddons')}
        {!showLink ? (
          <Txt variant="bodySmallBold" component="span">
            {t('Global:callCustomerSupportPhoneNumberDisplay')}
          </Txt>
        ) : (
          <InlineLink
            data-cy="call-customer-support-phone-number"
            variant="primary"
            href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
          >
            {t('Global:callCustomerSupportPhoneNumberDisplay')}
          </InlineLink>
        )}
      </Txt>
    </div>
  );
};
