import React from 'react';
import { ViewStyle } from 'react-native';
import Svg, { SvgProps, Path, G } from 'react-native-svg';
import { Color } from '@mfe/legacy/andromeda';

interface OpensInNewWindowProps extends SvgProps {
  size?: number;
  color?: Color;
  style?: ViewStyle;
}

const OpensInNewWindow = ({
  size = 32,
  color = Color.gray800,
  style,
}: OpensInNewWindowProps): JSX.Element => (
  <Svg width={size} height={size} style={style}>
    <G
      id="Symbols"
      stroke="none"
      strokeWidth={1}
      transform={`scale(${size / 32})`}
      fill="none"
      fillRule="evenodd"
    >
      <G id="icon-/-small-/-openIn" fill={color}>
        <Path
          d="M17.4104339,9 L17.4104339,10.5970189 L20.2770828,10.5970189 L14,16.8733032 L15.1266968,18 L21.4029811,11.7229172 L21.4029811,14.5895661 L23,14.5895661 L23,9 L17.4104339,9 Z M21.4444444,21.4444444 L10.5555556,21.4444444 L10.5555556,10.5555556 L15.2222222,10.5555556 L15.2222222,9 L10.5555556,9 C9.69222222,9 9,9.7 9,10.5555556 L9,21.4444444 C9,22.3 9.69222222,23 10.5555556,23 L21.4444444,23 C22.3,23 23,22.3 23,21.4444444 L23,16.7777778 L21.4444444,16.7777778 L21.4444444,21.4444444 Z"
          id="Fill-1"
        />
      </G>
    </G>
  </Svg>
);

export default OpensInNewWindow;
