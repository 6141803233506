import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect } from 'react';

import { selectConfig } from '@mfe/shared/redux/config';
import { BuyMore } from '@mfe/features/buy-more';
import {
  selectUtil,
  setOverviewPageStatistics,
} from '@mfe/to-be-migrated/redux/utils';
import {
  Storage,
  VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID,
} from '@mfe/shared/util';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  Surface,
  useDialogType,
  ModalContext,
  ModalLoading,
  MobileProfileButton,
} from '@mfe/shared/components';
import { Platform } from '@mfe/shared/schema-types';
import { removeAlert } from '@mfe/to-be-migrated/redux/alerts';
import { useTranslation } from 'react-i18next';
import { fetchBillingInfo } from '@mfe/to-be-migrated/redux/billingInfo';
import { selectPlanCharacteristics } from '@mfe/to-be-migrated/redux/plan';
import {
  getCharacteristics,
  getUsage,
  selectCurrentUsage,
} from '@mfe/to-be-migrated/redux/plan/planSlice';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import {
  getWorkOrders,
  selectWorkOrders,
} from '@mfe/to-be-migrated/redux/workOrders';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';

import { Header } from './header';
import { Layout } from './layout';
import { PlanPreview } from './plan-preview';
import { ACPBanner } from './components/ACPBanner/ACPBanner';
import { DataUsage } from './data-usage';
import { BalanceCard } from './components/BodySection/BalanceCard';
import { AddonUpsellCards } from './components/BodySection/AddonUpsellCards';
import MvAppAd from './components/BodySection/MVAppAd';
import AlertsSection from './components/AlertsSection';
import { OverviewArticlesSection } from './articles-section';
import { OverviewCommonQuestions } from './common-questions';

import styles from './Overview.module.scss';

type OverviewProps = {
  navigateToPage: (link: string) => void;
  navigateToACPPage: () => void;
  navigateToChangePlan: () => void;
};

const Overview = ({
  navigateToPage,
  navigateToACPPage,
  navigateToChangePlan,
}: OverviewProps): JSX.Element => {
  const { t } = useTranslation('Alerts');

  const {
    pageStats: {
      pages: {
        overview: { lastUpdatedTime },
      },
    },
  } = useSelector(selectUtil);

  const { platform, showACPBanner } = useSelector(selectConfig);

  const {
    characteristics: { isUnleashed, id: productTypeId },
  } = useSelector(selectPlanCharacteristics);

  const isVoicePlan = productTypeId === VOICE_ONLY_CONNECTION_PRODUCT_TYPE_ID;

  const {
    data: { monthlyDataCap },
  } = useSelector(selectCurrentUsage);

  const {
    user: {
      auth: { isAuthenticated },
      productKind,
    },
  } = useSelector(selectUser);

  const hasWirelessInternet =
    productKind === ProductInstanceTypes.WirelessInternet;

  const { workOrders } = useSelector(selectWorkOrders);

  const {
    userInfo: { isPreInstall },
  } = useSelector(selectUserInfo);
  const showDataUsage =
    !isVoicePlan && !isUnleashed && !isPreInstall && !hasWirelessInternet;

  const [dialogType, dialogTypeHandlers] = useDialogType();
  const { resetDialogType } = dialogTypeHandlers;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!lastUpdatedTime && isAuthenticated) {
      dispatch(setOverviewPageStatistics());
    }
  }, [dispatch, lastUpdatedTime, isAuthenticated]);

  useEffect(() => {
    dispatch(fetchBillingInfo('overview'));
    if (!monthlyDataCap) dispatch(getUsage());
    if (!productTypeId) dispatch(getCharacteristics());
    if (workOrders === null) dispatch(getWorkOrders());
    return () => {
      dispatch(removeAlert(t('changePlan.orderAlert.title')));
    };
  }, []);

  const mobileAppModal =
    platform !== Platform.Web && dialogType !== null ? (
      <React.Suspense
        fallback={<ModalLoading onCancel={() => resetDialogType()} />}
      >
        <Surface>
          <BuyMore />
        </Surface>
      </React.Suspense>
    ) : null;

  const handleManagePlan = () => {
    Storage.setItem('showInternetPlan', true);
    navigateToPage('Profile');
  };

  const handleViewDataUsage = () => navigateToPage('PlanUsage');

  const overviewPageContent = (
    <>
      <MobileProfileButton />
      <Layout>
        {showACPBanner && <ACPBanner navigateToACPPage={navigateToACPPage} />}
        <AlertsSection navigateToPage={navigateToPage} />
        <Header />
        <div data-cy="overview-body" className={styles['content']}>
          <div className={styles['balance']}>
            <BalanceCard />
          </div>
          <div className={styles['questions']}>
            <OverviewCommonQuestions />
          </div>
          {platform === Platform.Web && (
            <div className={styles['mvAppAd']}>
              <MvAppAd />
            </div>
          )}
          <div className={styles['infoContainer']}>
            <PlanPreview
              handleManagePlan={handleManagePlan}
              handleViewDataUsage={handleViewDataUsage}
            />
            {showDataUsage && (
              <DataUsage handleViewDetailedUsage={handleViewDataUsage} />
            )}
            <AddonUpsellCards handleManagePlan={handleManagePlan} />
            <OverviewArticlesSection />
          </div>
        </div>
      </Layout>
    </>
  );

  return (
    <ModalContext.Provider value={{ dialogType, dialogTypeHandlers }}>
      {mobileAppModal ?? overviewPageContent}
    </ModalContext.Provider>
  );
};

export default Overview;
