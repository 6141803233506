import React from 'react';
import WrapWithMobileStackNav from '../../../services/WrapWithMobileStackNav';
import { RouteRenderProps, NestedRoutes } from '../../utils';
import { TroubleshootLink } from '@mfe/legacy/mv/utils/Navigation';
import LiveChat from '../../../views/ContactUs';

import { Faq } from '@mfe/legacy/mv/views/FAQ';
import {
  TroubleshootProvider,
  TroubleshootingHome,
  TroubleshootingResults,
  RebootModem,
  FixNavigatorWrapper,
} from '@mfe/features/troubleshooting';

export const troubleshootRoutes: RouteRenderProps[] = [
  {
    path: TroubleshootLink.Troubleshooting,
    render: <TroubleshootingHome />,
    exact: true,
    fromParent: 0,
  },
  {
    path: TroubleshootLink.Faq,
    exact: true,
    render: <Faq />,
    fromParent: 1,
  },
  {
    path: TroubleshootLink.AgentChat,
    exact: true,
    render: <LiveChat />,
    fromParent: 1,
  },
  {
    path: TroubleshootLink.DiagnosticResults,
    render: (
      <WrapWithMobileStackNav navTranslationKey="DiagnosticResults">
        <TroubleshootingResults />
      </WrapWithMobileStackNav>
    ),
    exact: true,
    fromParent: 1,
  },
  {
    path: TroubleshootLink.FixNavigator,
    render: (
      <WrapWithMobileStackNav navTranslationKey="DiagnosticDetails">
        <FixNavigatorWrapper />
      </WrapWithMobileStackNav>
    ),
    exact: true,
    fromParent: 2,
  },
  {
    path: TroubleshootLink.Reboot,
    render: (
      <WrapWithMobileStackNav navTranslationKey="ModemReboot">
        <RebootModem />
      </WrapWithMobileStackNav>
    ),
    exact: true,
    fromParent: 1,
  },
];

const TroubleshootSwitch = (): JSX.Element => (
  <TroubleshootProvider>
    <NestedRoutes routes={troubleshootRoutes} />
  </TroubleshootProvider>
);

export default TroubleshootSwitch;
