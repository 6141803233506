import { Button, EmptyState, InlineLink, Surface, Txt } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { useDispatch, useSelector } from 'react-redux';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { AccountType, Platform } from '@mfe/shared/schema-types';
import { useTranslation } from 'react-i18next';

import styles from './styles.module.scss';
import { selectConfig } from '@mfe/shared/redux/config';
import { useDetectBrowser, useScreenResolution } from '@mfe/shared/util';
import { AddOnCard } from './add-on-card';
import { selectAddOns } from '@mfe/to-be-migrated/redux/addOns';

import { AddOnsLoading } from './loading';
import { Alert } from '@vst/beam-icons/illustrative-icons';

export const AddOns = () => {
  const { t } = useTranslation(['Profile', 'Global']);

  const { addOnsPricesAndDiscounts, loading, error } =
    useSelector(selectAddOns);

  const dispatch = useDispatch();

  const { isSmall, isExtraSmall } = useScreenResolution();

  const isMobile = isSmall || isExtraSmall;

  const browser = useDetectBrowser();

  const { platform } = useSelector(selectConfig);

  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const showLink = platform !== Platform.Web || browser === 'MobileWeb';

  const openLinkInNewTab = (e: React.MouseEvent<HTMLElement>, url: string) => {
    e.preventDefault();
    dispatch(openInNewTab(url));
  };

  const refreshPage = () => window.location.reload();

  const addOnsLink =
    accountType === AccountType.Residential
      ? t('Global:addOnsResidentialLink')
      : t('Global:addOnsSMBLink');

  if (loading) {
    return <AddOnsLoading />;
  }

  if (error) {
    return (
      <Surface
        variant="primary"
        radius={isSmall || isExtraSmall ? '0px' : '16px'}
        className={styles['error-container']}
      >
        <div className={styles['header']}>
          <Txt variant="bodyLargeBold">{t('Profile:addOns.title')}</Txt>
        </div>
        <EmptyState
          description={t('Profile:addOns.error.description')}
          icon={Alert}
          actions={{
            primary: {
              label: t('Profile:addOns.error.refreshButton'),
              onClick: refreshPage,
            },
          }}
        />
      </Surface>
    );
  }

  return (
    <Surface variant="primary" radius={isMobile ? '0px' : '16px'}>
      <div className={styles['header']}>
        <Txt variant="bodyLargeBold">{t('Profile:addOns.title')}</Txt>

        <InlineLink
          href={addOnsLink}
          openInNewLocation={true}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            openLinkInNewTab(e, addOnsLink);
          }}
        >
          <Button variant="secondary" icon={OpenInNew} iconPos="right">
            {t('Profile:addOns.seeMoreButton')}
          </Button>
        </InlineLink>
      </div>
      {addOnsPricesAndDiscounts.map((addOnOffer, index) => (
        <AddOnCard addOnOffer={addOnOffer} key={index} />
      ))}
      <div className={styles['footer']}>
        <Txt variant="bodySmallRegular">
          {t('Profile:addOns.manageAddons')}
          {showLink ? (
            <InlineLink
              data-cy="call-customer-support-phone-number"
              variant="primary"
              href={`tel:${t('Global:callCustomerSupportPhoneNumber')}`}
            >
              {t('Global:callCustomerSupportPhoneNumberDisplay')}
            </InlineLink>
          ) : (
            <Txt variant="bodySmallBold" component="span">
              {t('Global:callCustomerSupportPhoneNumberDisplay')}
            </Txt>
          )}
        </Txt>
      </div>
    </Surface>
  );
};
