import { useEffect, useRef, memo, useState } from 'react';

export type AEMContentProviderProps = {
  src: string;
  style?: React.CSSProperties;
  logError?: ({
    error,
    data,
    tags,
  }: {
    error: Error;
    data?: any;
    tags?: string[];
  }) => void;
  onLoad?: () => void;
  id?: string;
  htmlContent?: string;
};

const AEMContentProviderBase = (props: AEMContentProviderProps) => {
  const { src, logError, onLoad, id, htmlContent, style } = props;
  const divRef = useRef<HTMLDivElement>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    //for if the aemComponent has already been fetched
    if (htmlContent) {
      setContent(htmlContent);
      return;
    }

    fetchAEMComponent();

    function getContent(res: Response): Promise<string> {
      if (res.status === 200) {
        return res.text();
      } else {
        const errorMessage = `AEM Error Status Code ${res.status}`;
        setError(errorMessage);
        if (logError)
          logError({ error: new Error(errorMessage), tags: ['AEM'] });
        return Promise.resolve('');
      }
    }

    function fetchAEMComponent(): void {
      //for fetching aem components in components
      fetch(src)
        .then(getContent)
        .then(setContent)
        .catch((error) => {
          setError(error);
          if (logError) logError({ error, tags: ['AEM'] });
        })
        .finally(() => {
          if (onLoad) onLoad();
        });
    }

    function setContent(htmlContent: string): void {
      const slot = divRef?.current;
      if (!htmlContent || !slot) {
        return;
      }
      const fragment = document
        .createRange()
        .createContextualFragment(htmlContent);
      slot.innerHTML = '';
      slot.append(fragment);
    }
  }, [src, onLoad, htmlContent, logError]);

  if (error) return null;

  return <div style={style} data-cy={id} ref={divRef} id={id}></div>;
};

export const AEMContentProvider = memo(AEMContentProviderBase);
