import { Resolvers } from '@apollo/client';
import {
  AssessmentName,
  Query,
  QueryGetDiagnosticsArgs,
} from '@viasat/res-apps-lib/build/types/mv';

import { GET_DIAGNOSTICS_QUERY } from './queries';
import { ContextType, queryAPI, createDefaultHealthChecks } from './utils';
import {
  TroubleshootingInfo,
  HealthStatus,
  HealthChecks,
  HealthCheck,
  HealthCheckType,
  FixStartScreen,
  FixScreens,
} from './types';

const defaultHealthChecks = createDefaultHealthChecks();

const NON_ACTIVE_TROUBLESHOOTING: TroubleshootingInfo = {
  __typename: 'Troubleshooting',
  lastRan: null,
  healthChecks: defaultHealthChecks,
  houseOK: false,
  networkOK: false,
  errorToFix: null,
};

function getTypeStatuses(healthChecks: HealthChecks): {
  houseOK: boolean;
  networkOK: boolean;
} {
  let houseOK = true;
  let networkOK = true;

  Object.values(healthChecks)
    .filter((check: HealthCheck) => check.healthStatus === HealthStatus.Fail)
    .forEach((check: HealthCheck) => {
      if (check.type === HealthCheckType.Network) {
        networkOK = false;
      } else if (
        check.type === HealthCheckType.Home ||
        check.type === HealthCheckType.Account
      ) {
        houseOK = false;
      }
    });

  return { houseOK, networkOK };
}

//This is the order that the fixes should be in
const fixTypesOrder: FixStartScreen[] = [
  FixScreens.Configuration,
  FixScreens.Network,
  FixScreens.Dish,
  FixScreens.SoftReboot,
  FixScreens.HardRebootSpock,
  FixScreens.HardRebootSB2,
];

const findFirstErrorToFix = (
  healthChecks: HealthChecks
): AssessmentName | null => {
  for (const fixType in fixTypesOrder) {
    for (const [assessmentName, healthCheck] of Object.entries(healthChecks)) {
      if (healthCheck.healthStatus === HealthStatus.Pass) continue;
      if (healthCheck.fix !== fixType) continue;

      return assessmentName as AssessmentName;
    }
  }

  return null;
};

const resolver: Resolvers = {
  Query: {
    troubleshooting: async (
      _: unknown,
      {
        assessments,
        doRefresh,
        isAccountActive,
      }: QueryGetDiagnosticsArgs & { isAccountActive: boolean },
      { client }: ContextType
    ): Promise<TroubleshootingInfo | null> => {
      if (!isAccountActive) return NON_ACTIVE_TROUBLESHOOTING;

      const { getDiagnostics } = await queryAPI<Query, QueryGetDiagnosticsArgs>(
        client,
        {
          query: GET_DIAGNOSTICS_QUERY,
          variables: { assessments, doRefresh },
          fetchPolicy: doRefresh ? 'network-only' : 'cache-first',
        }
      );
      if (!getDiagnostics) return null;

      const healthChecks = defaultHealthChecks;
      getDiagnostics.forEach((assessment) => {
        if (
          assessment === null ||
          assessment.name === AssessmentName.CurrentWeatherStatus
        )
          return;

        healthChecks[assessment.name].healthStatus = assessment.isOK
          ? HealthStatus.Pass
          : HealthStatus.Fail;
      });

      return {
        __typename: 'Troubleshooting',
        lastRan: new Date().toISOString(),
        healthChecks,
        ...getTypeStatuses(healthChecks),
        errorToFix: findFirstErrorToFix(healthChecks),
      };
    },
  },
};

export const MODEM_REBOOT_TIME_SECONDS = 240;
export * from './queries';
export * from './utils';
export * from './types';

export default resolver;
