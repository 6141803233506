import React from 'react';
import { View, TouchableOpacity, StyleSheet } from 'react-native';
import { Text, Color, useNavigate } from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { HelpLink, TroubleshootLink } from '@mfe/legacy/mv/utils/Navigation';

import { useTroubleshootContext, RebootStates } from '../TroubleshootContext';

const helpStyles = StyleSheet.create({
  actionBtns: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingHorizontal: 22,
    alignItems: 'center',
    backgroundColor: Color.gray100,
    height: 58,
    marginBottom: 4,
  },
  btnsText: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  powerIcon: {
    marginTop: 2,
    marginLeft: -3,
  },
  headsetIcon: {
    marginTop: 1,
    marginLeft: -1,
  },
});

const ButtonRow = ({ children }: { children: React.ReactNode }) => (
  <View style={helpStyles.actionBtns}>
    <View style={helpStyles.btnsText}>{children}</View>
    <Icon name="chevron-right" color={Color.teal500} size={24} />
  </View>
);

export const QuickHelpLinks = (): JSX.Element => {
  const goTo = useNavigate();
  const { t } = useTranslation('Fix');
  const { loading, rebootState } = useTroubleshootContext();

  const isRebootModemDisabled =
    loading ||
    [
      RebootStates.ISSUING,
      RebootStates.PENDING_OFFLINE,
      RebootStates.PENDING_ONLINE,
    ].indexOf(rebootState) > -1;

  const RebootLink = (
    <TouchableOpacity
      onPress={(): void => goTo(TroubleshootLink.Reboot, { append: true })}
      disabled={isRebootModemDisabled}
      style={{ opacity: isRebootModemDisabled ? 0.5 : 1 }}
      testID="RebootMyModem-Button"
    >
      <ButtonRow>
        <Icon
          name="power"
          size={30}
          style={helpStyles.powerIcon}
          color={Color.teal500}
        />
        <Text style={{ marginLeft: 13 }}>{t('RebootMyModem')}</Text>
      </ButtonRow>
    </TouchableOpacity>
  );

  const FaqLink = (
    <TouchableOpacity
      onPress={(): void => goTo(HelpLink.Main)}
      testID="Faq-Button"
    >
      <ButtonRow>
        <Icon name="help-circle-outline" size={26} color={Color.teal500} />
        <Text style={{ marginLeft: 13 }}>{t('Faq')}</Text>
      </ButtonRow>
    </TouchableOpacity>
  );

  return (
    <View style={{ marginTop: 8, paddingBottom: 16 }} testID="QuickHelpLinks">
      {RebootLink}
      {FaqLink}
    </View>
  );
};
