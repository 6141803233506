import { Txt } from '@vst/beam';
import { useDispatch, useSelector } from 'react-redux';

import { useTranslation } from 'react-i18next';
import ViasatVoice from '../../../img/ViasatVoice';
import EasyCare from '../../../img/EasyCare';
import ViasatShield from '../../../img/ViasatShield';
import ViasatGlobe from '../../../img/ViasatGlobe';
import { AddOnCardLoading } from './AddOnCardLoading';
import styles from './AddOnSection.module.scss';
import {
  selfDescribingEvent,
  AnalyticsEventNames,
  selectAnalytics,
} from '@mfe/to-be-migrated/redux/analytics';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { selectUserInfo } from '@mfe/to-be-migrated/redux/userInfo';
import { useTrackTiming } from '@mfe/shared/util';
import { AccountType } from '@mfe/shared/schema-types';

export enum AddOnType {
  Voice = 'Voice',
  EasyCare = 'EasyCare',
  Shield = 'Shield',
  StaticIP = 'StaticIP',
}

interface AddOnCardProps {
  type: AddOnType;
  loading?: boolean;
}

const getAddOnIcon = (type: AddOnType): JSX.Element | null => {
  switch (type) {
    case AddOnType.Voice:
      return <ViasatVoice />;
    case AddOnType.EasyCare:
      return <EasyCare />;
    case AddOnType.Shield:
      return <ViasatShield />;
    case AddOnType.StaticIP:
      return <ViasatGlobe />;
    default:
      return null;
  }
};

const AddOnCardIcon = ({ type }: AddOnCardProps): JSX.Element | null => {
  const icon = getAddOnIcon(type);

  if (!icon) {
    return null;
  }

  return (
    <div data-cy="add-on-card-icon" className={styles['addOnCardIcon']}>
      {icon}
    </div>
  );
};

export const AddOnCard = ({ type, loading }: AddOnCardProps): JSX.Element => {
  const { t } = useTranslation(['Overview', 'Global']);
  const dispatch = useDispatch();
  const { display_platform, usageContext } = useSelector(selectAnalytics);
  const {
    userInfo: { accountType },
  } = useSelector(selectUserInfo);

  const isSMBAccount = accountType === AccountType.Smb;

  useTrackTiming({
    eventName: `AddOnCardLoaded_${type}`,
    isLoading: loading ?? false,
  });

  const titleText = t(`Overview:Advertisement.${type}.title`);
  const captionText = t(`Overview:Advertisement.${type}.caption`);
  const learnMoreURL = t(
    `Overview:Advertisement.${type}.${isSMBAccount ? 'link_SMB' : 'link'}`
  );
  const learnMoreText = t('Global:learnMore');

  const onClick = () => {
    dispatch(
      selfDescribingEvent({
        eventName: AnalyticsEventNames.AddonsSelected,
        vendor: 'com.viasat.care',
        data: {
          add_on_type: type,
          display_zone: 'overview_page',
          display_platform,
        },
        context: [usageContext],
      })
    );

    dispatch(openInNewTab(learnMoreURL));
  };

  return (
    <div data-cy="add-on-card" className={styles['addOnCardMargin']}>
      <div className={styles['addOnCard']}>
        {loading ? (
          <AddOnCardLoading />
        ) : (
          <>
            <div className={styles['addOnCardTitle']}>
              <AddOnCardIcon type={type} />
              <div
                data-cy="title-text"
                className={styles['addOnCardTitleText']}
              >
                <Txt variant="bodyLargeBold">{titleText}</Txt>
              </div>
            </div>
            <div data-cy="caption-text" className={styles['addOnCardCaption']}>
              <Txt variant="bodyLargeRegular">{captionText}</Txt>
            </div>
            <div className={styles['addOnCardLink']}>
              <a data-cy="add-on-card-link" onClick={onClick}>
                <Txt variant="labelSmall">{learnMoreText}</Txt>
              </a>
            </div>
          </>
        )}
      </div>
    </div>
  );
};
