import { FetchWithErrorsQuery } from '@mfe/shared/redux/graphql';
import { createSlice } from '@reduxjs/toolkit';

export interface AddOnsState {
  loading: boolean;
  error: FetchWithErrorsQuery['errors'] | null;
  addOns: {
    hasEasyCare?: boolean;
    hasVoice?: boolean;
    hasShield?: boolean;
    hasStaticIP?: boolean;
    hasOfficeHours?: boolean;
  };
  addOnsProductInstanceIds: string[];
  addOnsPricesAndDiscounts: [];
}

export const initialAddOnsState: AddOnsState = {
  loading: true,
  error: null,
  addOns: {},
  addOnsProductInstanceIds: [],
  addOnsPricesAndDiscounts: [],
};

export const addOnsSlice = createSlice({
  name: 'addOns',
  initialState: initialAddOnsState,
  reducers: {
    fetchAddOns: (state) => {
      state.loading = true;
    },
    setAddOns: (
      state,
      { payload }: { payload: Partial<AddOnsState['addOns']> }
    ) => {
      state.loading = false;
      state.addOns = { ...state.addOns, ...payload };
    },
    setAddOnsProductInstanceIds: (
      state,
      { payload }: { payload: string[] | [] }
    ) => {
      state.addOnsProductInstanceIds.push(...payload);
    },

    getAddOnsPricesAndDiscounts: (state) => {
      state.loading = true;
    },

    setAddOnsPricesAndDiscounts: (state, { payload }) => {
      state.loading = false;
      state.addOnsPricesAndDiscounts = payload;
    },

    setError: (state, { payload }) => {
      state.loading = false;
      state.error = payload;
    },
    resetAddOns: () => initialAddOnsState,
  },
});

export const {
  fetchAddOns,
  setAddOns,
  resetAddOns,
  setAddOnsProductInstanceIds,
  getAddOnsPricesAndDiscounts,
  setError,
  setAddOnsPricesAndDiscounts,
} = addOnsSlice.actions;
export const selectAddOns = (state: { addOns: AddOnsState }) => state.addOns;
