import { Spinner, Surface, Txt } from '@vst/beam';
import { useTranslation } from 'react-i18next';

import { Container } from '@mfe/shared/components';

import styles from './ConfirmPurchaseLoading.module.scss';

export const ConfirmPurchaseLoading = () => {
  const { t } = useTranslation('NewChangePlan');

  return (
    <Container>
      <Surface
        className={styles['container']}
        radius="16px"
        px={{ xs: '16px', md: '112px' }}
        my={{ xs: undefined, sm: '40px' }}
      >
        <div data-cy="ConfirmPurchaseLoading-spinner">
          <Spinner />
        </div>
        <Txt
          data-cy="ConfirmPurchaseLoading-title"
          variant="bodyLargeBold"
          color="subtle"
        >
          {t('confirmPurchase.title')}
        </Txt>
        <Txt
          data-cy="ConfirmPurchaseLoading-description"
          variant="bodyLargeRegular"
          color="subtle"
        >
          {t('confirmPurchase.description')}
        </Txt>
      </Surface>
    </Container>
  );
};
