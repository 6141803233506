import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';

import {
  HealthChecks,
  HealthCheck,
  HealthStatus,
  FixStartScreen,
} from './GraphQLResolver';
import { AssessmentName } from '@viasat/res-apps-lib/build/types/mv';

export function getDateTimeStrings(
  date: Date,
  language: string
): { date: string; time: string } {
  const dateString = format(date, 'MMMM do');
  const time = format(date, 'h:mm aa');
  return { date: dateString, time };
}

//This is the order that the fixes should be in
const healthCheckOrder: AssessmentName[] = [
  // Network
  AssessmentName.ServiceConfiguration,
  AssessmentName.ViasatNetwork,
  // Home
  AssessmentName.DishAlignment,
  AssessmentName.DishToModemConnection,
  AssessmentName.WifiModem,
  // Account
  AssessmentName.Billing,
  AssessmentName.DataUsage,
];

export const sortHealthChecks = (
  healthChecks: HealthChecks | null
): [AssessmentName, HealthCheck][] => {
  if (!healthChecks) return [];

  return healthCheckOrder.map((assessment) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return [assessment, healthChecks[assessment]];
  });
};

export const doAssessmentsPass = (
  healthChecks: HealthChecks | null,
  assessments: AssessmentName[]
): boolean => {
  let status = HealthStatus.Pass;
  if (!healthChecks) return false;

  for (const assessment of assessments) {
    if (assessment === AssessmentName.CurrentWeatherStatus) return false;

    if (!healthChecks[assessment]) {
      status = HealthStatus.Fail;
      break;
    }

    const assessmentStatus = healthChecks[assessment].healthStatus;
    if (assessmentStatus === HealthStatus.Fail || assessmentStatus === null) {
      status = HealthStatus.Fail;
      break;
    }
  }

  return status === HealthStatus.Pass;
};

export type FixNavigator = IterableIterator<FixStartScreen>;

export function* createFixNavigator(
  fixList: HealthChecks | null
): FixNavigator {
  const orderedFixList = sortHealthChecks(fixList);
  for (const [, fix] of orderedFixList) {
    yield fix.fix;
  }
}

export const useTimer = (
  timeLimitSeconds: number | undefined,
  onDone?: () => void
): { numSecondsElapsed: number } => {
  const [startTime] = useState(new Date());
  const [numSecondsElapsed, setNumSecondsElapsed] = useState(0);

  useEffect(() => {
    if (!timeLimitSeconds)
      return () => {
        /* empty */
      };

    const interval = setInterval(checkNumSecondsElapsed, 1000);
    return (): void => {
      clearInterval(interval);
    };
  }, [timeLimitSeconds]);

  if (!timeLimitSeconds) return { numSecondsElapsed: 0 };

  const checkNumSecondsElapsed = () => {
    const now = new Date();
    const newNumSecondsElapsed = Math.floor(
      (now.getTime() - startTime.getTime()) / 1000
    );
    setNumSecondsElapsed(newNumSecondsElapsed);
    if (newNumSecondsElapsed >= timeLimitSeconds) {
      if (onDone) {
        onDone();
      }
    }
  };

  return { numSecondsElapsed };
};
