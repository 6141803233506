import {
  AssignmentIndOutlined,
  BusinessCenterOutlined,
  PhoneIphoneOutlined,
  WifiOutlined,
} from '@vst/beam-icons/icons';
import { ProfileTab } from '@mfe/to-be-migrated/shared-platform-react/pages/Profile';
import { AccountType } from '@mfe/shared/schema-types';
import { TFunction } from 'i18next';

const getProfileHeaderLabels = (accountType: string, t: TFunction) => {
  const navIcon = {
    residential: {
      accountInfoIcon: AssignmentIndOutlined,
      contactInfoIcon: PhoneIphoneOutlined,
    },
    smb: {
      accountInfoIcon: AssignmentIndOutlined,
      contactInfoIcon: BusinessCenterOutlined,
    },
    common: {
      internetPlanIcon: WifiOutlined,
    },
  };

  const sendResidentialNavLabels = () => {
    return {
      accountInfoIcon: navIcon.residential.accountInfoIcon,
      contactInfoIcon: navIcon.residential.contactInfoIcon,
      internetPlanIcon: navIcon.common.internetPlanIcon,
      accountInfoLabel: t('nav', {
        context: ProfileTab.ResidentialAccount,
      }),
      contactInfoLabel: t('nav', {
        context: ProfileTab.ResidentialContact,
      }),
      internetInfoLabel: t('nav', {
        context: ProfileTab.InternetPlan,
      }),
    };
  };

  if (!accountType) {
    return { ...sendResidentialNavLabels() };
  }

  const isBusinessCustomer = accountType?.toUpperCase() === AccountType.Smb;

  if (isBusinessCustomer) {
    return {
      accountInfoIcon: navIcon.smb.accountInfoIcon,
      contactInfoIcon: navIcon.smb.contactInfoIcon,
      internetPlanIcon: navIcon.common.internetPlanIcon,
      accountInfoLabel: t('nav', {
        context: ProfileTab.SMBAccount,
      }),
      contactInfoLabel: t('nav', {
        context: ProfileTab.SMBBusiness,
      }),
      internetInfoLabel: t('nav', {
        context: ProfileTab.InternetPlan,
      }),
    };
  }

  return { ...sendResidentialNavLabels() };
};

export const getNavItems = (
  currentTab: string,
  accountType: string,
  t: TFunction
) => {
  const profileTabProps = getProfileHeaderLabels(accountType, t);

  const dropdownProps = [
    {
      icon: profileTabProps.accountInfoIcon,
      id: accountType + 'Account',
      label: profileTabProps.accountInfoLabel,
    },
    {
      icon: profileTabProps.contactInfoIcon,
      id:
        accountType +
        (accountType === AccountType.Residential ? 'Contact' : 'Business'),
      label: profileTabProps.contactInfoLabel,
    },
    {
      icon: profileTabProps.internetPlanIcon,
      id: ProfileTab.InternetPlan,
      label: profileTabProps.internetInfoLabel,
    },
  ];

  const selectedItem = dropdownProps.filter((item) => item.id === currentTab);
  const extendedItems = dropdownProps.filter((item) => item.id !== currentTab);
  return { navBarItems: dropdownProps, selectedItem, extendedItems };
};
