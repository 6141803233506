import { AssessmentName } from '@viasat/res-apps-lib/build/types/mv';

import {
  HealthCheckType,
  FixStartScreen,
  HealthStatus,
  HealthCheck,
  FixScreens,
  HealthChecks,
} from './types';

import {
  ApolloClient,
  QueryOptions,
  OperationVariables,
  MutationOptions,
  InMemoryCache,
} from '@apollo/client';

export interface ContextType {
  client: ApolloClient<InMemoryCache>;
  cache: InMemoryCache;
}
// Wrapper function that properly handles error results for use in local resolvers
export async function queryAPI<
  T,
  TVariables extends OperationVariables = OperationVariables
>(
  client: ApolloClient<InMemoryCache>,
  queryOptions: QueryOptions<TVariables>
): Promise<T> {
  let data;
  try {
    const result = await client.query<T, TVariables>(queryOptions);
    data = result.data;
  } catch (e: any) {
    throw new Error(e);
  }
  return data;
}
// Wrapper function that properly handles error results for use in local resolvers
export async function mutateAPI<
  T,
  TVariables extends OperationVariables = OperationVariables
>(
  client: ApolloClient<InMemoryCache>,
  mutateOptions: MutationOptions<T, TVariables>
): Promise<T | null | undefined> {
  let data;
  try {
    const result = await client.mutate<T, TVariables>(mutateOptions);
    data = result.data;
  } catch (e: any) {
    throw new Error(e);
  }
  return data;
}

const defaultCheck = (
  fix: FixStartScreen,
  type: HealthCheckType
): HealthCheck => ({
  healthStatus: HealthStatus.Fail,
  type,
  fix,
});

export const createDefaultHealthChecks = (): HealthChecks =>
  ({
    [AssessmentName.ServiceConfiguration]: defaultCheck(
      FixScreens.Configuration,
      HealthCheckType.Network
    ),
    [AssessmentName.ViasatNetwork]: defaultCheck(
      FixScreens.Network,
      HealthCheckType.Network
    ),
    // TODO seems this is unused
    // [AssessmentName.CurrentWeatherStatus]: defaultCheck(),
    [AssessmentName.DishAlignment]: defaultCheck(
      FixScreens.Dish,
      HealthCheckType.Home
    ),
    [AssessmentName.DishToModemConnection]: defaultCheck(
      FixScreens.SoftReboot,
      HealthCheckType.Home
    ),
    [AssessmentName.WifiModem]: defaultCheck(
      FixScreens.SoftReboot,
      HealthCheckType.Home
    ),
    [AssessmentName.Billing]: defaultCheck(
      FixScreens.Configuration,
      HealthCheckType.Account
    ),
    [AssessmentName.DataUsage]: defaultCheck(
      FixScreens.Configuration,
      HealthCheckType.Account
    ),
  } as HealthChecks);
