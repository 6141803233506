import React, { useEffect } from 'react';
import { useNavigate } from '@mfe/legacy/andromeda';
import { TroubleshootLink } from '@mfe/legacy/mv/utils/Navigation';
import { useTrackEventFunction } from '@mfe/legacy/mv/containers';

import { RebootStates, useTroubleshootContext } from './TroubleshootContext';
import { SoftRebootCountdown } from './FixNavigator/Fixs';

export const RebootModem = (): JSX.Element => {
  const navigate = useNavigate();
  const trackEvent = useTrackEventFunction();
  const { loading, rebootModem, rebootState, setRebootState } =
    useTroubleshootContext();

  useEffect((): void => {
    if (!loading && rebootState === RebootStates.NOT_ISSUED) {
      rebootModem().then((result) => {
        setRebootState(RebootStates.ISSUING);
        if (result?.data?.rebootModem?.rebootSuccess) {
          setRebootState(RebootStates.PENDING_OFFLINE);
          trackEvent('Modem Soft Reboot', 'successful');
        } else {
          setRebootState(RebootStates.ERROR);
          trackEvent('Modem Soft Reboot', 'false');
        }
      });
    }

    if (!loading && rebootState === RebootStates.SUCCEEDED) {
      setRebootState(RebootStates.NOT_ISSUED);
      navigate(TroubleshootLink.Troubleshooting, { back: true });
    }
  }, [loading, rebootState]);

  return (
    <div style={{ backgroundColor: 'white' }}>
      <SoftRebootCountdown
        onCountdownFinished={() => {
          navigate(TroubleshootLink.Troubleshooting, { back: true });
        }}
      />
    </div>
  );
};
