import React, { useState } from 'react';
import { TouchableOpacity, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import { Text, Color } from '@mfe/legacy/andromeda';
import { useTimer } from '../utils';
import { TimerIcon } from './TimerIcon';

const styles = StyleSheet.create({
  countdownText: {
    textAlign: 'center',
    marginTop: 27,
    marginBottom: 16,
    color: Color.teal500,
  },
  countdownButton: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: 16,
    fontSize: 18,
  },
  countdownButtonText: {
    color: Color.teal500,
    marginLeft: 7,
    marginTop: 3,
  },
});

export function formatTimeString(totalSeconds: number): string {
  const minutes: number = Math.floor(totalSeconds / 60);
  const seconds: number = totalSeconds % 60;
  if (seconds < 10) {
    return `${minutes.toString()}:0${seconds.toString()}`;
  } else {
    return `${minutes.toString()}:${seconds.toString()}`;
  }
}

export interface CountdownButtonProps {
  startTimerText: string;
  sideEffect?: () => void;
}

const TIME_LIMIT_SECONDS = 30;
const Countdown = ({ sideEffect }: { sideEffect?: () => void }) => {
  const { numSecondsElapsed } = useTimer(TIME_LIMIT_SECONDS, () => {
    if (sideEffect) {
      sideEffect();
    }
  });

  if (numSecondsElapsed > TIME_LIMIT_SECONDS) {
    return null;
  }

  return (
    <Text bold style={styles.countdownText} testID="countdown">
      {formatTimeString(numSecondsElapsed)}
    </Text>
  );
};

export const CountdownButton = ({
  startTimerText,
  sideEffect,
}: CountdownButtonProps): JSX.Element | null => {
  const [isCounting, setCounting] = useState(false);
  const { t } = useTranslation('Fix');

  if (isCounting) {
    return <Countdown sideEffect={sideEffect} />;
  }

  return (
    <TouchableOpacity
      style={styles.countdownButton}
      onPress={(): void => setCounting(true)}
    >
      <TimerIcon />
      <Text testID="countdownButton" style={styles.countdownButtonText}>
        {t(startTimerText)}
      </Text>
    </TouchableOpacity>
  );
};
