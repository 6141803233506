import React, { useEffect } from 'react';
import { SafeAreaView, ScrollView, View, StyleSheet } from 'react-native';
import { Text, Color, useDimensionContext } from '@mfe/legacy/andromeda';
import { useQuery } from '@apollo/client';
import { GET_ORDER_SUMMARY } from '../queries/orderSummary';
import DrawerLoadingState from '../components/DrawerLoadingState';
import { useTranslation } from 'react-i18next';
import { useTrackEventFunction, errorLogger } from '../containers';
import { JSONStringifyCyclic, formatString, toTitleCase } from '../utils';
import { format } from 'date-fns';
import {
  NewOrderInfo,
  ChargeTypes,
  GetFulfillmentPayload,
} from '@viasat/res-apps-lib/build/types/mv';
import Status from '../components/Status';
import { getPlanPriceWithPromo, getPromoNumOfMonths } from './utils';

const styles = StyleSheet.create({
  container: {
    marginHorizontal: 20,
  },
  wrapper: {
    marginHorizontal: 24,
  },
  orderFinePrint: {
    lineHeight: 20,
    color: Color.gray600,
  },
  text: {
    textAlign: 'left',
  },
  accountNumberTitle: {
    marginTop: 53,
  },
  orderDate: {
    marginTop: 15,
  },
  addressBox: {
    marginTop: 26,
    paddingVertical: 20,
    backgroundColor: Color.gray100,
    borderRadius: 8,
  },
  addressBoxText: {
    paddingLeft: 24,
  },
  bottomPrint: {
    marginTop: 28,
    marginBottom: 30,
  },
  additionalCharge: {
    marginTop: 26,
  },
  priceText: {
    color: Color.teal500,
  },
  headerText: {
    marginTop: 64,
  },
  billingBox: {
    backgroundColor: Color.gray500,
    borderRadius: 12,
    marginTop: 44,
  },
  billingBoxText: {
    color: Color.white,
    justifyContent: 'center',
    paddingTop: 22,
    paddingBottom: 19,
    paddingHorizontal: 20,
    display: 'flex',
  },
  itemsWrapper: {
    marginTop: 16,
  },
  originalAmount: {
    marginTop: 17,
    textDecorationLine: 'line-through',
    lineHeight: 16,
  },
});

const AdditionalChargeTypes: (ChargeTypes | undefined)[] = [
  ChargeTypes.OtherCharge,
  ChargeTypes.SetupCharge,
  ChargeTypes.EasyCareCharge,
  ChargeTypes.VoipCharge,
  ChargeTypes.MonthlyLeaseCharge,
];

const AdditionalCharge = ({
  name,
  amount,
}: NewOrderInfo): JSX.Element | null => {
  const { t } = useTranslation('OrderSummary');
  return (
    <View>
      <Text caption>
        {name.startsWith(amount ?? '') || !amount
          ? name
          : formatString(t('additionalCharge'), {
              price: amount,
              nameOfFee: name,
            })}
      </Text>
    </View>
  );
};

const BottomPrint = (): JSX.Element => {
  const { t } = useTranslation('OrderSummary');
  return (
    <View style={[styles.bottomPrint, styles.wrapper]}>
      <Text
        caption
        italic
        style={[styles.orderFinePrint, styles.text]}
        testID="orderFinePrint"
      >
        {t('orderFinePrint')}
      </Text>
    </View>
  );
};

const OrderSummary = (): JSX.Element => {
  const logger = errorLogger();
  const { t } = useTranslation(['OrderSummary', 'PayBill']);
  const trackEvent = useTrackEventFunction();
  const { isMobile } = useDimensionContext();
  const { data, error, loading, refetch } = useQuery<{
    getFulfillment: GetFulfillmentPayload;
  }>(GET_ORDER_SUMMARY, {
    notifyOnNetworkStatusChange: true,
  });

  useEffect((): void => {
    trackEvent('Nav', 'OrderSummary_clicked', { label: 'sub_nav' });
  }, []);

  if (loading) {
    return <DrawerLoadingState testID="orderSummaryLoading" />;
  }
  if (error || !data) {
    logger(new Error(JSONStringifyCyclic(error)));
    return (
      <Status
        bodyData={t('error')}
        retry={async (): Promise<void> => {
          refetch();
        }}
        style={
          !isMobile
            ? { height: '100%', paddingBottom: 40, paddingHorizontal: 30 }
            : {}
        }
      />
    );
  }

  const orderDate = format(
    new Date(data.getFulfillment.createDate),
    'MMM d, yyyy'
  );

  const address = data.getFulfillment.address;
  const formattedAddress = [
    ...(address?.addressLine?.map(toTitleCase) ?? []),
    `${toTitleCase(address?.municipality ?? '')}, ${address?.region}`,
    address?.postalCode,
  ].join('\n');

  const newOrderInfo = data.getFulfillment?.newOrderInfo;
  const plan = newOrderInfo?.find(
    (info) => info?.type === ChargeTypes.MonthlyServiceCharge
  );
  const otherCharges =
    newOrderInfo?.filter((info): info is NewOrderInfo =>
      AdditionalChargeTypes.includes(info?.type)
    ) ?? [];

  const promo = otherCharges.find((item: NewOrderInfo) =>
    item.name.includes('Off for')
  );
  const planPricePromoApplied = getPlanPriceWithPromo(promo, plan);
  const promoNumOfMonths = getPromoNumOfMonths(promo);

  return (
    <SafeAreaView testID="orderSummary">
      <ScrollView bounces={false}>
        <View style={styles.container}>
          <View style={styles.wrapper}>
            <Text.H3 heavy style={styles.headerText} testID="orderSummaryLabel">
              {t('OrderSummary')}
            </Text.H3>
          </View>

          <View style={styles.billingBox} testID="billingBox">
            <Text
              semiBold
              caption
              style={styles.billingBoxText}
              testID="headerText"
            >
              {t('FirstPayment')}
            </Text>
          </View>

          <View style={styles.wrapper}>
            <Text
              small
              semiBold
              style={[styles.accountNumberTitle, styles.text]}
              testID="accountNumberLabel"
            >
              {t('AccountNumber')}
            </Text>

            <Text.H3 heavy style={styles.text} testID="accountNumber">
              {data.getFulfillment?.accountNumber}
            </Text.H3>

            <Text
              caption
              light
              style={[styles.orderDate, styles.text]}
              testID="orderDate"
            >
              {formatString(t('orderDate'), { orderDate })}
            </Text>
          </View>

          <View style={styles.addressBox} testID="addressBox">
            <Text
              bold
              caption
              style={styles.addressBoxText}
              testID="addressLabel"
            >
              {t('address')}
            </Text>
            <Text small style={styles.addressBoxText} testID="address">
              {formattedAddress}
            </Text>
          </View>

          <View style={[styles.additionalCharge, styles.wrapper]} testID="plan">
            <Text bold caption testID="planName">
              {plan?.name}
            </Text>
            {planPricePromoApplied && (
              <Text bold small style={styles.originalAmount}>
                {formatString(t('planAmountFormatted'), {
                  planAmount: plan?.amount,
                })}
              </Text>
            )}
            {planPricePromoApplied ? (
              <Text.H3 heavy style={styles.priceText} testID="planValue">
                {formatString(t('planPricePromoApplied'), {
                  planPricePromoApplied: planPricePromoApplied,
                })}
              </Text.H3>
            ) : (
              <Text.H3 heavy style={styles.priceText} testID="planValue">
                {formatString(t('planAmountFormatted'), {
                  planAmount: plan?.amount,
                })}
              </Text.H3>
            )}

            {promoNumOfMonths && (
              <Text caption light style={styles.text}>
                {formatString(t('promoTerms'), {
                  planPrice: plan?.amount,
                  numMonths: promoNumOfMonths,
                })}
              </Text>
            )}
          </View>

          <View
            style={[styles.itemsWrapper, styles.wrapper]}
            testID="itemsWrapper"
          >
            {otherCharges.map((item) => (
              <AdditionalCharge key={item.name} {...item} />
            ))}
          </View>
          <BottomPrint />
        </View>
      </ScrollView>
    </SafeAreaView>
  );
};

export default OrderSummary;
