import React from 'react';
import Svg, { G, Circle, Path } from 'react-native-svg';

const CircleCheckOK = ({ testID }: { testID?: string }): JSX.Element => (
  <Svg width={34} height={34} testID={testID}>
    <G fill="none" fillRule="evenodd">
      <Circle fill="#FFF" cx={17} cy={17} r={17} />
      <Circle
        cx={14.571}
        cy={14.571}
        r={14.571}
        fill="#99D733"
        transform="translate(2.914 1.943)"
      />
      <Path
        stroke="#FFF"
        fill="#FFF"
        fillRule="nonzero"
        d="M24.043 13.79l-7.494 7.824-3.435-3.586.88-.919 2.555 2.66 6.613-6.898z"
      />
    </G>
  </Svg>
);

export default CircleCheckOK;
