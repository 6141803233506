import { Surface, Txt, Button } from '@vst/beam';
import { OpenInNew } from '@vst/beam-icons/icons';
import { useTranslation } from 'react-i18next';
import styles from './styles.module.scss';
import { openInNewTab } from '@mfe/to-be-migrated/redux/utils';
import { useDispatch } from 'react-redux';

export const NoAddons = (): JSX.Element => {
  const { t } = useTranslation(['Overview', 'Global']);
  const dispatch = useDispatch();

  return (
    <Surface
      data-cy="no-addons-container"
      className={styles['no-addons-container']}
    >
      <Txt variant="bodySmallRegular">{t('Overview:addOns.noAddons')}</Txt>
      <Button
        variant="secondary"
        icon={OpenInNew}
        iconPos="right"
        onClick={(e) => {
          e.preventDefault();
          dispatch(openInNewTab(t('Overview:addOns.shopAddonsLink')));
        }}
      >
        {t('Overview:addOns.shopAddons')}
      </Button>
    </Surface>
  );
};
