import React from 'react';
import { Platform, TouchableOpacity, View, StyleSheet } from 'react-native';
import Icon from 'react-native-vector-icons/MaterialCommunityIcons';
import { Color, useNavigate } from '@mfe/legacy/andromeda';
import FeedbackButton from './FeedbackButton';

const styles = StyleSheet.create({
  buttonContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 3,
    paddingLeft: 16,
    paddingRight: 10,
  },
  button: {
    alignSelf: 'flex-start',
  },
  icon: {
    paddingTop: 12,
    paddingBottom: 6,
    paddingLeft: 3,
    paddingRight: 10,
  },
});

interface ProfileButtonProps {
  white?: boolean;
}

const ProfileButton = ({
  white = false,
}: ProfileButtonProps): JSX.Element | null => {
  const navigate = useNavigate();

  return (
    <View style={styles.buttonContainer}>
      {Platform.OS !== 'web' ? <FeedbackButton /> : <View />}
      <TouchableOpacity
        testID="profileButton"
        style={styles.button}
        onPress={(): void => navigate('Profile')}
      >
        <Icon
          name="account-circle-outline"
          size={32}
          color={white ? Color.white : Color.teal500}
          style={styles.icon}
        />
      </TouchableOpacity>
    </View>
  );
};

export default ProfileButton;
