import React, { useEffect, useState } from 'react';
import { Animated, Image, View, StyleSheet } from 'react-native';
import { Color, Text } from '@mfe/legacy/andromeda';

import Billing from './LoadingGifs/billing.gif';
import DataUsage from './LoadingGifs/data-usage.gif';
import DishAlignment from './LoadingGifs/dish-alignment.gif';
import DishToModem from './LoadingGifs/dish-to-modem-communication.gif';
import Modem from './LoadingGifs/modem.gif';
import NetworkToDish from './LoadingGifs/network-to-dish-communication.gif';
import Network from './LoadingGifs/network.gif';
import ServiceConfiguration from './LoadingGifs/service-configuration.gif';

const ImageSources = [
  NetworkToDish,
  Network,
  DishAlignment,
  DishToModem,
  Modem,
  Billing,
  DataUsage,
  ServiceConfiguration,
];

const style = StyleSheet.create({
  padding: {
    paddingVertical: 8,
  },
});

//Side of the image. Used for calculations
const IMAGESIZE = 166;

export const TSLoadingAnimation = (): JSX.Element => {
  const [value] = useState(new Animated.Value(0));

  //This is the animation for the card sliding down
  useEffect((): void => {
    Animated.spring(value, {
      toValue: 100,
      delay: 100,
      useNativeDriver: false,
    }).start();
  }, []);

  //We go from 0 to 100 for height because the div that it covers has a height of 520ish is already calc'd
  const height = value.interpolate({
    inputRange: [0, 100],
    outputRange: ['0%', '100%'],
  });

  return (
    <Animated.View
      style={{
        zIndex: 1,
        position: 'absolute',
        width: '100%',
        backgroundColor: Color.white,
        height,
        justifyContent: 'center',
        alignItems: 'center',
        // ...shadowgiver(5),
      }}
    >
      <View style={[{ height: IMAGESIZE, width: '100%' }, style.padding]}>
        <AnimatedImage />
      </View>
      <View style={style.padding}>
        <Text light italic>
          Checking System Status...
        </Text>
      </View>
    </Animated.View>
  );
};

//This makes an easy way to speed up the animation
const AnimationSpeedBase = 500;

const AnimatedImage = (): JSX.Element => {
  const [fromRight, setRight] = useState(new Animated.Value(0));
  const [index, setIndex] = useState(0);

  //This is the animation for the image sliding
  useEffect((): (() => void) => {
    Animated.sequence([
      Animated.timing(fromRight, {
        //Slide In effect
        toValue: 75,
        delay: AnimationSpeedBase,
        duration: AnimationSpeedBase,
        useNativeDriver: false,
      }),
      Animated.timing(fromRight, {
        toValue: 150,
        delay: AnimationSpeedBase * 3, //How long to keep it in the middle
        duration: AnimationSpeedBase,
        useNativeDriver: false,
      }),
    ]).start();

    //The below timeout allows the image to cycle
    const timeout = setTimeout((): void => {
      setRight(new Animated.Value(0));
      setIndex((index + 1) % ImageSources.length);
    }, AnimationSpeedBase * 6);
    return (): void => {
      clearTimeout(timeout);
    };
  }, [index]);

  //move the image from the right to the left. negative margin makes it go over the center
  const right = fromRight.interpolate({
    inputRange: [0, 150],
    outputRange: ['-25%', '125%'],
  });
  //for a slight fade effect
  const opacity = fromRight.interpolate({
    inputRange: [100, 120],
    outputRange: [1, 0],
  });

  //We use the negative right margin to put the absolute anchor over the middle of the image
  return (
    <Animated.View
      style={{
        right,
        opacity,
        position: 'absolute',
        marginRight: -IMAGESIZE / 2,
        width: IMAGESIZE,
      }}
    >
      <Image
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        source={ImageSources[index]}
        style={{ width: IMAGESIZE, height: IMAGESIZE }}
      />
    </Animated.View>
  );
};
