import React from 'react';
import { View, StyleSheet } from 'react-native';
import { gql } from '@apollo/client';
import {
  Text,
  Button,
  Color,
  ButtonText,
  useNavigate,
  useSimpleNavigate,
} from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import { useQuery } from '@apollo/client';
import { AssessmentName } from '@viasat/res-apps-lib/build/types/mv';

import CircleErrorExclamation from './CircleErrorExclamation';
import CircleCheckOK from './CircleCheckOK';
import { formatString, useAccountStatus } from '@mfe/legacy/mv/utils';
import { TroubleshootLink } from '@mfe/legacy/mv/utils/Navigation';

import {
  HealthCheck,
  HealthStatus,
  HealthCheckType,
  HealthChecks,
} from '../GraphQLResolver';
import { useTroubleshootContext } from '../TroubleshootContext';

import ResultBackground from '../ErrorBackground';
import { getDateTimeStrings, sortHealthChecks } from '../utils';
import { Query } from '@mfe/shared/schema-types';

const Styles = StyleSheet.create({
  row: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  diagnostic: {
    paddingLeft: 30,
    paddingRight: 30,
    marginTop: 2,
    marginBottom: 2,
  },
  fixedHeight: {
    height: 60,
  },
  ok: {
    backgroundColor: Color.gray100,
  },
  error: {
    backgroundColor: Color.red100,
  },
  troubleshoot: {
    justifyContent: 'space-between',
  },
  topGutter: {
    marginTop: 5,
  },
  statusMessage: {
    textAlign: 'center',
    paddingTop: 12,
    paddingHorizontal: 20,
  },
});

const ReCheckButton = ({ hasErrors }: { hasErrors: boolean }): JSX.Element => {
  const simpleNavigate = useSimpleNavigate();
  const goTo = useNavigate();
  const { loading, refetch } = useTroubleshootContext();
  const { t } = useTranslation(['Fix', 'Common']);

  const buttonColor = hasErrors ? Color.white : Color.teal400;
  const textColor = { color: hasErrors ? Color.teal500 : Color.white };

  return (
    <View style={{ marginTop: 20, marginBottom: 20, width: '100%' }}>
      <Button
        fill
        disabled={loading}
        color={buttonColor}
        onPress={(): void => {
          if (!loading && !hasErrors) {
            refetch();
          }
          hasErrors
            ? simpleNavigate(
                `/Troubleshooting/${TroubleshootLink.FixNavigator}`
              )
            : goTo(TroubleshootLink.Troubleshooting);
        }}
      >
        <ButtonText style={textColor}>
          {hasErrors ? t('SeeDetails') : t('CheckStatus')}
        </ButtonText>
      </Button>
    </View>
  );
};

const LastRanText = ({ textProps }: { textProps: any }) => {
  const { i18n, t } = useTranslation(['Fix', 'Common']);
  const { data } = useTroubleshootContext();

  const lastRan = new Date(data?.troubleshooting?.lastRan ?? Date.now());
  const { date, time } = getDateTimeStrings(lastRan, i18n.language);

  return (
    <View style={Styles.topGutter}>
      <Text small {...textProps} style={{ alignSelf: 'center' }}>
        {t('LastRan', { date, time })}
      </Text>
    </View>
  );
};

const ResultsSummary = () => {
  const { t } = useTranslation(['Fix', 'Common']);
  const { data } = useTroubleshootContext();
  const hasErrors =
    !data?.troubleshooting?.houseOK || !data?.troubleshooting?.networkOK;
  let statusMessage = t('WeFoundNoIssues');
  if (hasErrors) {
    const errorType =
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      data?.troubleshooting?.healthChecks[
        data?.troubleshooting?.errorToFix as AssessmentName
      ]?.type;
    statusMessage = formatString(t('AnErrorHasBeenDetected'), {
      problem: t(`${errorType}ErrorType`),
    });
  }
  const textColor = (hasErrors && { white: true }) || {};

  return (
    <View
      style={{
        height: '30%',
        padding: 40,
        paddingTop: 0,
      }}
    >
      <ResultBackground isSuccessful={!hasErrors} />
      <Text {...textColor} style={Styles.statusMessage}>
        {statusMessage}
      </Text>
      <ReCheckButton hasErrors={hasErrors} />
      <LastRanText textProps={textColor} />
    </View>
  );
};

const ResultRow = ({
  label,
  status,
}: {
  label: AssessmentName;
  status: HealthStatus;
}): JSX.Element => {
  const { t } = useTranslation(['Fix', 'Common']);
  const text = t(label);

  switch (status) {
    case HealthStatus.Fail:
      return (
        <View
          style={[
            Styles.diagnostic,
            Styles.error,
            Styles.fixedHeight,
            Styles.row,
            Styles.troubleshoot,
          ]}
        >
          <Text>{text}</Text>
          <CircleErrorExclamation />
        </View>
      );
    case HealthStatus.Pass:
      return (
        <View
          style={[
            Styles.diagnostic,
            Styles.row,
            Styles.troubleshoot,
            Styles.ok,
            Styles.fixedHeight,
          ]}
        >
          <Text testID="ts-screen-text">{text}</Text>
          <CircleCheckOK />
        </View>
      );
    default:
      throw new Error('Unsupported Status Provided');
  }
};

const ResultsTable = () => {
  const { t } = useTranslation(['Fix', 'Common']);
  const { isAccountPreInstall } = useAccountStatus();

  const { data } = useTroubleshootContext();
  const { data: wifiData } = useQuery<Query>(
    gql`
      query getFullWifiInfo($refetchData: Boolean) {
        getWifiInfo(refetchData: $refetchData) {
          isWiFiModem
        }
      }
    `,
    {
      variables: { refetchData: false },
      notifyOnNetworkStatusChange: true,
    }
  );

  const isWiFiModem =
    isAccountPreInstall || (wifiData?.getWifiInfo?.isWiFiModem ?? true);
  const healthChecks = data?.troubleshooting?.healthChecks ?? {};
  const sortedHealthChecks = sortHealthChecks(
    healthChecks as HealthChecks
  ).filter(
    ([assessmentName, _]: [AssessmentName, unknown]) =>
      assessmentName !== AssessmentName.WifiModem || isWiFiModem
  );

  const SectionHeader = (healthCheckType: HealthCheckType) => (
    <View style={[Styles.row, Styles.diagnostic, Styles.fixedHeight]}>
      <Text caption>{t(healthCheckType).toUpperCase()}</Text>
    </View>
  );

  const SectionChecks = (healthCheckType: HealthCheckType) => (
    <View>
      {sortedHealthChecks
        .filter(([_, { type }]) => type === healthCheckType)
        .map(
          ([assessmentName, { healthStatus }]: [
            AssessmentName,
            HealthCheck
          ]) => (
            <ResultRow
              key={assessmentName}
              label={assessmentName}
              status={healthStatus}
            />
          )
        )}
    </View>
  );

  return (
    <View>
      {Object.values(HealthCheckType).map(
        (healthCheckType: HealthCheckType): JSX.Element => (
          <View key={healthCheckType}>
            {SectionHeader(healthCheckType)}
            {SectionChecks(healthCheckType)}
          </View>
        )
      )}
    </View>
  );
};

export const TroubleshootingResults = () => {
  const { loading } = useTroubleshootContext();
  const goTo = useNavigate();

  if (loading) {
    goTo(TroubleshootLink.Troubleshooting);
    return null;
  }

  return (
    <div style={{ backgroundColor: 'white' }}>
      <ResultsSummary />
      <ResultsTable />
    </div>
  );
};
