import React from 'react';
import { Color } from '@mfe/legacy/andromeda';
import Svg, { Path } from 'react-native-svg';

export const TimerIcon = (): JSX.Element => {
  return (
    <Svg width="22" height="22" viewBox="0 0 22 22">
      <Path fill="none" d="M0 0h22v22H0V0z" />
      <Path
        fill={Color.teal500}
        d="M14 1h-4c-.55 0-1 .45-1 1s.45 1 1 1h4c.55 0 1-.45 1-1s-.45-1-1-1zm-2 13c.55 0 1-.45 1-1V9c0-.55-.45-1-1-1s-1 .45-1 1v4c0 .55.45 1 1 1zm7.03-6.61l.75-.75c.38-.38.39-1.01 0-1.4l-.01-.01c-.39-.39-1.01-.38-1.4 0l-.75.75C16.07 4.74 14.12 4 12 4c-4.8 0-8.88 3.96-9 8.76C2.87 17.84 6.94 22 12 22c4.98 0 9-4.03 9-9 0-2.12-.74-4.07-1.97-5.61zM12 20c-3.87 0-7-3.13-7-7s3.13-7 7-7 7 3.13 7 7-3.13 7-7 7z"
      />
    </Svg>
  );
};

export default TimerIcon;
