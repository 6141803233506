import { Icon, Txt } from '@vst/beam';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ShoppingCart from '@vst/beam-icons/icons/ShoppingCart';

import { CartSummary } from '../../CartSummary';
import { Collapsible } from '../../../shared/Collapsible';

import {
  formatPrice,
  selectChangePlan,
} from '@mfe/to-be-migrated/redux/changePlan';
import { useScreenResolution } from '@mfe/shared/util';
import { GetPlanOffersPayload } from '@mfe/shared/schema-types';
import { selectLocale } from '@mfe/to-be-migrated/redux/locale';
import { formatPriceWithCurrency } from '@mfe/to-be-migrated/redux/changePlan';

import styles from './CollapsibleCartSummary.module.scss';

interface CollapsibleCartSummaryProps {
  selectedPlan: GetPlanOffersPayload;
  handleBackNavigation: (e: React.MouseEvent<HTMLElement>) => void;
}

export const CollapsibleCartSummary = ({
  handleBackNavigation,
  selectedPlan,
}: CollapsibleCartSummaryProps) => {
  const { t } = useTranslation('NewChangePlan', {
    keyPrefix: 'orderReview.cartDetails.cartSummary',
  });

  const { isExtraSmall, isSmall } = useScreenResolution();
  const isMobile = isExtraSmall || isSmall;

  const {
    locale: { userLocale },
  } = useSelector(selectLocale);

  const {
    cart: {
      prices: {
        monthly: { totalWithoutPromos },
        oneTime: { feesAndDiscounts },
      },
    },
    requiresWorkOrder,
  } = useSelector(selectChangePlan);

  const { price, priceCurrency, isEquipmentChangeNeeded } = selectedPlan;

  const priceWithCurrency = formatPrice(
    userLocale,
    totalWithoutPromos ? totalWithoutPromos : price,
    priceCurrency
  );

  const pricingText = isMobile
    ? t('monthlyPricingMobile')
    : t('monthlyPricingDesktop');

  const oneTimeTotal = feesAndDiscounts?.reduce(
    (sum, feeAndDiscount) => sum + (feeAndDiscount.total ?? 0),
    0
  );

  const formattedOneTimeTotal = formatPriceWithCurrency(
    userLocale,
    oneTimeTotal,
    priceCurrency,
    0
  );

  const shouldDisplayOneTimeFee =
    isEquipmentChangeNeeded && feesAndDiscounts && feesAndDiscounts?.length > 0;

  return (
    <Collapsible
      classNames={{
        summary: styles['cart-accordion-summary'],
      }}
      summary={
        <div
          data-cy="collapsible-cart-summary"
          style={{
            display: 'flex',
            alignItems: 'center',
            flexGrow: 1,
            gap: '8px',
          }}
        >
          <Icon icon={ShoppingCart} color="gray_600" />
          <Txt
            variant="smallRegular"
            color="subtle"
            data-cy="collapsible-cart-summary-pricing-text"
          >
            {pricingText}
            <Txt
              component="span"
              variant="smallBold"
              color="regular"
              data-cy="collapsible-cart-summary-price"
            >
              {priceWithCurrency}
            </Txt>
          </Txt>

          {shouldDisplayOneTimeFee && (
            <Txt
              variant="smallRegular"
              color="subtle"
              data-cy="collapsible-cart-summary-one-time-fees"
              style={{ marginLeft: 'auto' }}
            >
              {isMobile ? t('oneTimeFeesMobile') : t('oneTimeFeesDesktop')}
              <Txt
                component="span"
                variant="smallBold"
                color="regular"
                data-cy="collapsible-cart-summary-one-time-fees-total"
              >
                {formattedOneTimeTotal}
              </Txt>
            </Txt>
          )}
        </div>
      }
      content={
        <CartSummary
          selectedPlan={selectedPlan}
          handleBackNavigation={handleBackNavigation}
          requiresWorkOrder={requiresWorkOrder}
        />
      }
    />
  );
};
