import { gql } from '@apollo/client';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import {
  FetchWithErrorsQuery,
  graphqlQueryWithErrors,
} from '@mfe/shared/redux/graphql';
import { ProductInstanceTypes } from '@mfe/shared/graphql/PSM/types';

import {
  displayServiceCallAlert,
  getWorkOrders,
  setWorkOrders,
  setWorkOrdersError,
} from './workOrdersSlice';
import { resetAlerts } from '../alerts';
import { filterServiceCallWorkOrders } from '../../serverUtils';
import { selectUser } from '../auth';
import { waitForProductKind } from '../utils/utilsSagas';

export const GET_WORK_ORDERS = gql`
  query getWorkOrders {
    getWorkOrders {
      externalWorkOrderId
      workOrderType
      schedule {
        from
        to
      }
    }
  }
`;

export function* fetchWorkOrders() {
  yield call(waitForProductKind);

  const {
    user: { productKind },
  } = yield select(selectUser);

  if (productKind === ProductInstanceTypes.WirelessInternet) return;

  const workOrdersResponse: FetchWithErrorsQuery = yield call(
    graphqlQueryWithErrors,
    { query: GET_WORK_ORDERS, fetchPolicy: 'network-only' }
  );

  const { data, errors, runtimeError } = workOrdersResponse;

  if (errors || runtimeError) {
    yield put(setWorkOrdersError(runtimeError ?? errors));
    return;
  }

  if (data?.getWorkOrders) {
    yield put(setWorkOrders(data?.getWorkOrders));

    const serviceCallWorkOrders = filterServiceCallWorkOrders(
      data?.getWorkOrders
    );

    if (serviceCallWorkOrders.length > 0 && serviceCallWorkOrders[0].schedule) {
      yield put(displayServiceCallAlert(serviceCallWorkOrders[0]));
    }
  }
}

export function* watchWorkOrders() {
  yield all([
    takeLatest(getWorkOrders.type, fetchWorkOrders),
    takeLatest(resetAlerts.type, fetchWorkOrders),
  ]);
}
