import React from 'react';
import { View, ViewProps, StyleSheet } from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  Text,
  Color,
  Button,
  useNavigate,
  ButtonText,
  useDimensionContext,
} from '@mfe/legacy/andromeda';
import { formatString } from '@mfe/legacy/mv/utils';
import { TabLink } from '@mfe/legacy/mv/utils/Navigation';

import TroubleshootingErrorBg from './TroubleshootingErrorBg';

const styles = StyleSheet.create({
  container: {
    minHeight: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    marginHorizontal: 16,
  },
  description: {
    marginTop: 12,
    marginHorizontal: 16,
  },
  text: {
    paddingHorizontal: 68,
    textAlign: 'center',
    lineHeight: 24,
  },
  button: {
    marginTop: 25,
    backgroundColor: Color.white,
  },
  contactButton: {
    marginTop: 12,
  },
  buttonText: {
    color: Color.red400,
  },
  contactButtonText: {
    color: Color.white,
  },
  loading: {
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
  },
});

interface NotAllowedProps extends ViewProps {
  isSuspended?: boolean;
  isDisconnected?: boolean;
}

const TroubleshootingNotAllowed = ({
  isSuspended = false,
  isDisconnected = false,
  style = {},
  ...otherProps
}: NotAllowedProps): JSX.Element => {
  const { t } = useTranslation(['Fix', 'PayBill', 'Common']);
  const { height } = useDimensionContext();
  const goTo = useNavigate();
  const contactButtonStyle = isDisconnected
    ? styles.button
    : styles.contactButton;
  const contactButtonTextStyle = isDisconnected
    ? styles.buttonText
    : styles.contactButtonText;

  const accountStatus = isSuspended ? t('Suspended') : t('Disconnected');
  const title = formatString(t('NoTsTitle'), { accountStatus });
  if (__DEV__ && !isSuspended && !isDisconnected) {
    console.error('Account status must be either suspended or disconnected');
  }

  return (
    <View style={[styles.container, style, { height }]} {...otherProps}>
      <TroubleshootingErrorBg
        width="100%"
        height="100%"
        preserveAspectRatio="none"
        testID="ts-not-allowed-bg"
        style={{ ...StyleSheet.absoluteFillObject, zIndex: -1 }}
      />
      <Text.H3
        heavy
        white
        testID="ts-not-allowed-title"
        style={[styles.title, styles.text]}
      >
        {title}
      </Text.H3>
      <Text
        white
        testID="ts-not-allowed-description"
        style={[styles.description, styles.text]}
      >
        {t('NoTsDescription')}
      </Text>
      {!isDisconnected && (
        <Button
          testID="ts-not-allowed-make-payment-button"
          style={styles.button}
          onPress={(): void => {
            goTo(TabLink.Billing);
          }}
        >
          <ButtonText style={styles.buttonText}>{t('MakePayment')}</ButtonText>
        </Button>
      )}
      <Button
        tertiary
        style={contactButtonStyle}
        testID="ts-not-allowed-button"
        onPress={(): void => goTo('agent-chat', { append: true, drawer: true })}
      >
        <ButtonText style={contactButtonTextStyle}>{t('ContactUs')}</ButtonText>
      </Button>
    </View>
  );
};

export default TroubleshootingNotAllowed;
