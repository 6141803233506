import { gql } from '@apollo/client';

export const GET_ORDER_SUMMARY = gql`
  query getOrderSummary {
    getFulfillment {
      createDate
      installationDate {
        scheduleDate
        scheduleTime
      }
      newOrderInfo {
        type
        name
        amount
        discount
      }
      accountNumber
      address {
        addressLine
        postalCode
        municipality
        region
        countryCode
      }
    }
  }
`;
