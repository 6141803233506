import React from 'react';
import { View, StyleSheet } from 'react-native';
import Svg, {
  G,
  Polygon,
  Circle,
  LinearGradient,
  Stop,
  Defs,
  Rect,
  SvgProps,
} from 'react-native-svg';
import { Color } from '@mfe/legacy/andromeda';

interface ResultBackgroundProps {
  isSuccessful?: boolean;
  encircle?: boolean;
}

interface StatusBGProps extends SvgProps {
  isSuccessful?: boolean;
}

interface StatusFGProps extends SvgProps {
  isSuccessful?: boolean;
  encircle?: boolean;
  color?: string | undefined;
  strokeWidth?: string | number;
  scale?: string | number;
}

const styles = StyleSheet.create({
  imageBG: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    zIndex: -1,
    height: '100%',
  },
  imageFG: {
    alignSelf: 'center',
    alignItems: 'center',
  },
});

export const StatusBG = ({
  width = 375,
  height = 170,
  isSuccessful,
  ...otherProps
}: StatusBGProps): JSX.Element => (
  <View style={StyleSheet.absoluteFillObject}>
    <Svg width={width} height={height} viewBox="0 0 320 170" {...otherProps}>
      <Defs>
        <LinearGradient id="gradient" x1="0%" y1="0%" x2="100%" y2="100%">
          <Stop stopColor="#8DF2ED" offset="0%" />
          <Stop stopColor="#DDFA96" offset="100%" />
        </LinearGradient>
      </Defs>
      <Rect
        width="100%"
        height="100%"
        fill={isSuccessful ? 'url(#gradient)' : '#A82200'}
      />
      <Polygon fill={Color.white} points="0,0 0,60 320,45 320,0" />
    </Svg>
  </View>
);

export const StatusFG = ({
  width = 128,
  height = 128,
  encircle = false,
  color = undefined,
  strokeWidth = '5',
  isSuccessful = true,
  scale = 1,
  ...otherProps
}: StatusFGProps): JSX.Element => {
  if (color === undefined) {
    if (isSuccessful) color = '#0AA5AB';
    else color = '#A82200';
  }
  const translate = scale === 1.2 ? 10.0 : 20.0;

  return (
    <Svg
      width={width}
      height={height}
      viewBox="0 0 128 128"
      preserveAspectRatio="xMinYMin meet"
      {...otherProps}
    >
      {encircle && <Circle fill="white" cx="64" cy="64" r="64" />}
      <Circle
        fill="none"
        cx="64"
        cy="64"
        r="46.5"
        stroke={color}
        strokeWidth={strokeWidth}
      />
      {isSuccessful ? (
        <G transform={`translate(${translate}, ${translate})`}>
          <Polygon
            fill={color}
            stroke={color}
            fillRule="nonzero"
            points="61 35.6286353 39.7428571 58 30 47.7464579 32.4977143 45.1178225 39.7428571 52.7240865 58.5022857 33"
            transform={`scale(${scale})`}
          />
        </G>
      ) : (
        <>
          <Rect x="60.46" y="42.25" width="8.09" height="26.31" fill={color} />
          <Rect x="60.46" y="76.65" width="8.09" height="8.09" fill={color} />
        </>
      )}
    </Svg>
  );
};

const ResultBackground = ({
  isSuccessful = true,
  encircle = true,
}: ResultBackgroundProps): JSX.Element => (
  <>
    <StatusBG
      testID={`StatusBG`}
      width="100%"
      height="100%"
      preserveAspectRatio="none"
      isSuccessful={isSuccessful}
      style={styles.imageBG}
    />
    <StatusFG
      testID={`StatusFG`}
      width="50%"
      height="50%"
      preserveAspectRatio="xMidYMid meet"
      encircle={encircle}
      isSuccessful={isSuccessful}
      style={styles.imageFG}
    />
  </>
);

export default ResultBackground;
