import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { SectionAlert, Txt } from '@vst/beam';

import { selectUsageTier } from '@mfe/to-be-migrated/redux/plan';

import { UsageInfoAlert } from './usage-info-alert';
import { getPercentageScoreAndColor } from './utils';
import { UnleashedUsageSummaryError } from './error';

import styles from './styles.module.scss';

import { UnleashedUsageSummaryLoading } from './loading';

export const UnleashedUsageSummary = () => {
  const { t } = useTranslation('Usage', { keyPrefix: 'unleashed' });

  const { tier, loading, errors } = useSelector(selectUsageTier);

  if (tier === null && loading) {
    return <UnleashedUsageSummaryLoading />;
  }

  if (errors) {
    return <UnleashedUsageSummaryError />;
  }

  if (tier === null) {
    return null;
  }

  const { beakPosition, color, aemFeature } = getPercentageScoreAndColor(tier);

  const halfOfScoreContentBox = '40px';

  return (
    <div data-cy="unleashed-usage-summary" className={styles['containerWhite']}>
      <Txt variant="headerCapsSmall" color="subtle">
        {t('title')}
      </Txt>
      <div>
        <div>
          <div
            className={styles['scoreContent']}
            style={{
              marginLeft: `calc(${beakPosition} - ${halfOfScoreContentBox})`,
            }}
          >
            <Txt variant="tinyRegular" color="regular">
              {t('yourScore')}
            </Txt>
            <BeakPointDownSvg />
          </div>
        </div>
        <div className={styles['bar']} />
        <div className={styles['typicalScoreBox']}>
          <Txt
            variant="labelTiny"
            color="subtle"
            className={styles['leastData']}
          >
            {t('leastData')}
          </Txt>
          <Txt
            variant="labelTiny"
            color="subtle"
            className={styles['mostData']}
          >
            {t('mostData')}
          </Txt>
          <div className={styles['typicalScoreContent']}>
            <BeakPointUpSvg />
            <Txt variant="tinyRegular" color="regular">
              {t('typicalScore')}
            </Txt>
          </div>
        </div>
      </div>
      {aemFeature && (
        <SectionAlert showIcon={false} variant={color ?? undefined}>
          <UsageInfoAlert aemFeature={aemFeature} />
        </SectionAlert>
      )}
    </div>
  );
};

const BeakPointDownSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="8"
      viewBox="0 0 20 8"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.5 7.03C8.88 8.324 11.12 8.324 12.5 7.03L18.1548 1.7296C18.8162 1.10961 18.3775 0 17.4709 0L2.52911 0C1.62253 0 1.18379 1.10961 1.84523 1.7296L7.5 7.03Z"
        fill="#202E39"
      />
    </svg>
  );
};

const BeakPointUpSvg = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="8"
      viewBox="0 0 20 8"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.5 0.970488C11.12 -0.323512 8.88 -0.323512 7.5 0.970488L1.84523 6.27089C1.18379 6.89088 1.62253 8.00049 2.52911 8.00049H17.4709C18.3775 8.00049 18.8162 6.89088 18.1548 6.27089L12.5 0.970488Z"
        fill="#202E39"
      />
    </svg>
  );
};
