import React from 'react';
import { StyleSheet } from 'react-native';
import { Card, Color, Text } from '@mfe/legacy/andromeda';
import { View, Image } from 'react-native';
import { StatusFG } from '../ErrorBackground';
import { useTranslation } from 'react-i18next';
import AppColors from '@mfe/legacy/mv/styles/colors';
import { useAccountStatus } from '@mfe/legacy/mv/utils';
import { useTrackEventFunction } from '@mfe/legacy/mv/containers';

import HomeImage from './home.jpg';
import NetworkWeather from './network-weather.jpg';

export type CardTypes = 'Home' | 'Network';
interface StatusCardProps {
  type: CardTypes;
  hasError: boolean;
  onStatusPressed: () => void;
}

const styles = StyleSheet.create({
  circleIcon: {
    position: 'absolute',
    width: 52,
    height: 52,
    zIndex: 1,
    right: '11%',
    bottom: '23%',
    borderRadius: 26,
    justifyContent: 'center',
    alignItems: 'center',
    alignContent: 'center',
  },
  image: {
    height: 175,
    width: '100%',
    top: -25,
  },
  marginStyle: {
    flexGrow: 1,
    marginBottom: 12,
  },
  statusCardContent: {
    overflow: 'hidden',
    flexGrow: 1,
    borderRadius: 4,
    backgroundColor: AppColors.whiteEDF5F9,
  },
  text: {
    position: 'absolute',
    left: 16,
    bottom: 12,
  },
  viewWrapper: {
    zIndex: 0,
    position: 'absolute',
    width: '200%',
    bottom: -10,
    left: -10,
    height: '46%',
    transform: [{ rotate: '-5deg' }],
  },
});

const Icon = ({ hasError }: { hasError: boolean }): JSX.Element => {
  return (
    <StatusFG
      isSuccessful={!hasError}
      color={hasError ? Color.white : Color.teal500}
      scale={!hasError ? 1.2 : 1}
      width="100%"
      height="100%"
      strokeWidth={7}
    />
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getIconSource(type: CardTypes): any {
  switch (type) {
    case 'Home':
      return HomeImage;
    case 'Network':
      return NetworkWeather;
    default:
      //This state should never happen
      throw new Error('Unsupported Diagnostic Card Type');
  }
}

export const StatusCard = ({
  type,
  hasError,
  onStatusPressed,
}: StatusCardProps): JSX.Element => {
  const { t } = useTranslation('HelpMeFix');
  const { isAccountPreInstall: isDataUnavailable } = useAccountStatus();
  const source = getIconSource(type);
  const backgroundColor = hasError ? Color.red200 : Color.white;
  const textColor = hasError && { white: true };
  const trackEvent = useTrackEventFunction();

  const CircleIcon: JSX.Element = (
    <View style={[styles.circleIcon, { backgroundColor }]}>
      <Icon hasError={hasError} />
    </View>
  );

  return (
    <Card
      removeContentStyle
      marginStyle={styles.marginStyle}
      onPress={(): void => {
        trackEvent('Troubleshooting Option', 'selected', {
          property: type.toLowerCase(),
        });
        onStatusPressed();
      }}
      testID={`${type}-diag-card`}
    >
      <View style={styles.statusCardContent}>
        <Image source={source} style={styles.image} resizeMode="contain" />
        <View style={[styles.viewWrapper, { backgroundColor }]} />
        {!isDataUnavailable && CircleIcon}
        <View style={styles.text}>
          <Text small semiBold {...textColor}>
            {t(type)}
          </Text>
        </View>
      </View>
    </Card>
  );
};
