import React, { SVGProps } from 'react';
import { colors } from '@vst/beam';

const TabNavHeader = ({
  width = '300%',
  height = '50%',
  style,
  ...otherProps
}: SVGProps<SVGSVGElement>): JSX.Element => {
  return (
    <div style={style}>
      <svg
        width={width}
        height={height}
        viewBox={'0 256 320 365'}
        {...otherProps}
      >
        <defs>
          <linearGradient
            x1="21.01%"
            y1="10.228%"
            x2="21.01%"
            y2="79.147%"
            id="prefix__a"
          >
            <stop stopColor={colors.teal[300]} offset="0%" />
            <stop stopColor={colors.blue[600]} offset="100%" />
          </linearGradient>
          <linearGradient
            x1="-20.849%"
            y1="72.256%"
            x2="66.882%"
            y2="59.622%"
            id="prefix__b"
          >
            <stop stopColor={colors.teal[300]} offset="0%" />
            <stop stopColor={colors.blue[500]} offset="100%" />
          </linearGradient>
        </defs>
        <g fill="none" fillRule="evenodd">
          <path
            fill="url(#prefix__a)"
            transform="rotate(180 160.5 182.5)"
            d="M323 0v365L107 365 0 294V156z"
          />
          <path
            fill="url(#prefix__b)"
            opacity={0.6}
            transform="matrix(-1 0 0 1 323 0)"
            d="M323 307L0 264 2 50.598 247 0h75z"
          />
        </g>
      </svg>
    </div>
  );
};

export default TabNavHeader;
