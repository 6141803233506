import React, { useEffect } from 'react';
import { View, StyleSheet, ImageSourcePropType } from 'react-native';
import { Text, Color, useNavigate } from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import { TroubleshootLink } from '@mfe/legacy/mv/utils/Navigation';
import { useTrackEventFunction } from '@mfe/legacy/mv/containers';

import { useTroubleshootContext } from '../TroubleshootContext';
import { FixScreens, MODEM_REBOOT_TIME_SECONDS } from '../GraphQLResolver';
import { useTimer } from '../utils';
import {
  GenericFix,
  FixButton,
  ContactUsButton,
  Explanation,
} from './FixComponents';
import { useFixNavigator } from './FixNavigatorContext';
import { CountdownButton, formatTimeString } from './CountdownButton';

export const styles = StyleSheet.create({
  fixExplanation: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    paddingVertical: 6,
    paddingHorizontal: 29,
  },
  countdownText: {
    textAlign: 'center',
    marginTop: 27,
    marginBottom: 16,
    color: Color.teal500,
  },
});

const Configuration = () => (
  <GenericFix
    issueDescription={'ConfigurationError'}
    image={require('./imgs/service-config-icon-main.jpg')}
  >
    <ContactUsButton />
  </GenericFix>
);

const Network = () => {
  const { goToNextFix } = useFixNavigator();

  return (
    <GenericFix
      issueDescription={'NetworkError'}
      image={require('./imgs/viasat-network-icon-main.jpg')}
    >
      <Explanation key="NetworkSolution" />
      <FixButton text="GotIt" onPress={goToNextFix} />
    </GenericFix>
  );
};

const Dish = () => (
  <GenericFix
    issueDescription={'DishError'}
    image={require('./imgs/dish-icon-main.jpg')}
  >
    <ContactUsButton />
  </GenericFix>
);

const SoftReboot = () => (
  <GenericFix
    issueDescription={'SoftRebootError'}
    image={require('./imgs/modem-icon-main.jpg')}
  >
    <Explanation key="SoftRebootSolution" />
    <FixButton text="Reboot" goToScreen={FixScreens.SoftRebootCountdown} />
    <FixButton text="AlreadyTried" goToScreen={FixScreens.HardRebootSpock} />
  </GenericFix>
);

const SoftRebootConnection = () => {
  const { checkModem, setCurrentFixScreen } = useFixNavigator();

  useEffect(() => {
    checkModem({
      onModemHealthy: () => setCurrentFixScreen(FixScreens.RebootSuccess),
      onModemStillUnhealthy: () =>
        setCurrentFixScreen(FixScreens.HardRebootSpock),
    });
  }, []);

  return (
    <GenericFix
      issueDescription={'SoftRebootConnection'}
      image={require('./imgs/modem-icon-main.jpg')}
    >
      <Explanation key="SoftRebootConnectionSolution" />
      <ContactUsButton />
    </GenericFix>
  );
};

export const SoftRebootCountdown = ({
  onCountdownFinished,
}: {
  onCountdownFinished: () => void;
}) => {
  const { t } = useTranslation('Fix');
  const trackEvent = useTrackEventFunction();
  const { setCurrentFixScreen } = useFixNavigator();
  const { rebootModem } = useTroubleshootContext();
  const { numSecondsElapsed } = useTimer(MODEM_REBOOT_TIME_SECONDS, () => {
    if (onCountdownFinished) {
      onCountdownFinished();
    } else {
      setCurrentFixScreen(FixScreens.SoftRebootConnection);
    }
  });

  useEffect(() => {
    rebootModem().then((result): void => {
      if (!result) {
        trackEvent('Modem Soft Reboot', 'false');
        setCurrentFixScreen(FixScreens.HardRebootSpock);
      } else {
        if (result.data?.rebootModem?.rebootSuccess) {
          trackEvent('Modem Soft Reboot', 'successful');
        } else {
          trackEvent('Modem Soft Reboot', 'false');
        }
      }
    });
  }, []);

  return (
    <GenericFix
      issueDescription={'SoftRebooting'}
      image={require('./imgs/modem-icon-main.jpg')}
    >
      <View style={styles.fixExplanation}>
        <Text testID="explanation">{t('SoftRebootingSolution')}</Text>
        <Text semiBold style={styles.countdownText}>
          {formatTimeString(numSecondsElapsed)}
        </Text>
      </View>
    </GenericFix>
  );
};

const RebootSuccess = () => {
  const goTo = useNavigate();

  return (
    <GenericFix
      issueDescription="RebootSuccess"
      image={require('./imgs/modem-icon-main.jpg')}
    >
      <Explanation key="RebootSuccessExplanation" />
      <FixButton
        text="ItWorked"
        onPress={() => {
          goTo(TroubleshootLink.DiagnosticResults);
        }}
      />
      <FixButton text="ItDidNot" goToScreen={FixScreens.Configuration} />
    </GenericFix>
  );
};

const GenericHardReboot = ({ image }: { image: ImageSourcePropType }) => (
  <GenericFix issueDescription="HardRebootError" image={image}>
    <Explanation key="HardRebootSolution" />
    <CountdownButton startTimerText="StartTimer" />
    <FixButton text="Next" goToScreen={FixScreens.HardRebootConnection} />
    <FixButton text="AlreadyTried" goToScreen={FixScreens.Configuration} />
  </GenericFix>
);

const HardRebootSB2 = () => (
  <GenericHardReboot image={require('./imgs/sb2-unplug-icon-main.jpg')} />
);
const HardRebootSpock = () => (
  <GenericHardReboot image={require('./imgs/spock-unplug-icon-main.jpg')} />
);

const HardRebootConnection = () => (
  <GenericFix
    issueDescription={'HardRebootConnection'}
    image={require('./imgs/modem-icon-main.jpg')}
  >
    <Explanation key="HardRebootConnectionExplanation" />
    <FixButton
      text="TestConnection"
      tertiary
      goToScreen={FixScreens.HardRebootConnectionNoTimer}
    />
  </GenericFix>
);

const HardRebootConnectionNoTimer = () => {
  const { checkModem, setCurrentFixScreen } = useFixNavigator();

  useEffect(() => {
    checkModem({
      onModemHealthy: () => setCurrentFixScreen(FixScreens.RebootSuccess),
      onModemStillUnhealthy: () =>
        setCurrentFixScreen(FixScreens.HardRebootFailure),
    });
  }, []);

  return (
    <GenericFix
      issueDescription={'HardRebootConnection'}
      image={require('./imgs/modem-icon-main.jpg')}
    >
      <Explanation key="HardRebootConnectionSolution" />
      <ContactUsButton />
    </GenericFix>
  );
};

const HardRebootFailure = () => (
  <GenericFix
    issueDescription={'HardRebootFailure'}
    image={require('./imgs/hard-reboot-fail-icon.jpg')}
  >
    <ContactUsButton />
  </GenericFix>
);

export const FixScreenComponents: Record<
  FixScreens,
  (props: any) => JSX.Element
> = {
  Configuration,
  Network,
  Dish,
  SoftReboot,
  SoftRebootCountdown,
  SoftRebootConnection,
  HardRebootSpock,
  HardRebootSB2,
  HardRebootConnection,
  HardRebootConnectionNoTimer,
  HardRebootFailure,
  RebootSuccess,
};
