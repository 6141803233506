import React from 'react';
import { View, Image, StyleSheet, ImageSourcePropType } from 'react-native';
import { Button, ButtonProps, Text, useNavigate } from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';

import { FixScreens } from '../GraphQLResolver';
import { useFixNavigator } from './FixNavigatorContext';

const styles = StyleSheet.create({
  fixContainer: {
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
  },
  fixImage: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  fixIssue: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
    paddingTop: 27,
    paddingHorizontal: 29,
  },
  issueIcon: {
    height: 125,
    width: 147,
  },
  issueText: {
    textAlign: 'left',
    marginTop: 15,
  },
  fixText: {
    marginTop: 0,
    marginBottom: 15,
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'stretch',
  },
  fixButton: {
    marginTop: 16,
    marginHorizontal: 25,
  },
  contactUsButton: {
    marginTop: 40,
    marginHorizontal: 25,
  },
});

export const Explanation = ({ key }: { key: string }) => {
  const { t } = useTranslation('Fix');

  return (
    <View style={styles.fixText}>
      <Text testID="explanation">{t(key)}</Text>
    </View>
  );
};

export const ContactUsButton = () => {
  const { t } = useTranslation('Fix');
  const goTo = useNavigate();

  return (
    <Button
      fill
      testID="ts-results-contact"
      style={styles.contactUsButton}
      onPress={(): void => {
        goTo('agent-chat', { drawer: true });
      }}
    >
      {t('ContactUs')}
    </Button>
  );
};

export const FixButton = ({
  text,
  goToScreen,
  onPress,
  ...otherProps
}: { text: string; goToScreen?: FixScreens } & Omit<
  ButtonProps,
  'children'
>) => {
  const { t } = useTranslation('Fix');
  const { setCurrentFixScreen } = useFixNavigator();

  const onFixButtonPress = (e: any) => {
    if (onPress) onPress(e);
    if (goToScreen) setCurrentFixScreen(goToScreen);
  };

  return (
    <Button style={styles.fixButton} onPress={onFixButtonPress} {...otherProps}>
      {t(text)}
    </Button>
  );
};

export const GenericFix = ({
  image,
  issueDescription,
  children,
}: {
  image: ImageSourcePropType;
  issueDescription: string;
  children?: React.ReactNode;
}) => {
  const { t } = useTranslation('Fix');

  return (
    <View style={styles.fixContainer}>
      <View style={styles.fixIssue}>
        <View style={styles.fixImage}>
          <Image source={image} style={styles.issueIcon} />
        </View>
        <Text semiBold testID="issue" style={styles.issueText}>
          {t(issueDescription)}
        </Text>
      </View>
      {children}
    </View>
  );
};
