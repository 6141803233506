import React, { useContext, useState, useEffect, useRef } from 'react';
import { Alert, Platform } from 'react-native';
import { useTranslation } from 'react-i18next';
import {
  useQuery,
  useMutation,
  ApolloError,
  FetchResult,
} from '@apollo/client';
import { useSelector } from 'react-redux';
import { selectUser } from '@mfe/to-be-migrated/redux/auth';
import {
  ProductInstanceStatus,
  Query,
  Mutation,
  QueryGetDiagnosticsArgs,
} from '@mfe/shared/schema-types';
import { errorLogger } from '@mfe/legacy/mv/containers/analytics';
import {
  GET_TROUBLESHOOTING,
  REBOOT_MODEM,
  GET_MODEM_STATUS,
  TroubleshootingInfo,
} from './GraphQLResolver';

export enum RebootStates {
  NOT_ISSUED = 'NOT_ISSUED',
  ISSUING = 'ISSUING',
  PENDING_OFFLINE = 'PENDING_OFFLINE',
  PENDING_ONLINE = 'PENDING_ONLINE',
  ERROR = 'ERROR',
  SUCCEEDED = 'SUCCEEDED',
}

const Context = React.createContext<{
  data: { troubleshooting: TroubleshootingInfo } | undefined;
  loading: boolean;
  error: boolean;
  refetch: (args?: QueryGetDiagnosticsArgs) => Promise<boolean>;
  rebootState: RebootStates;
  setRebootState: (input: RebootStates) => void;
  rebootModem: () => Promise<FetchResult<Mutation>>;
}>({
  data: {} as any,
  loading: false,
  error: false,
  rebootState: RebootStates.NOT_ISSUED,
  setRebootState: (input: RebootStates): void => undefined,
  refetch: async (args?: QueryGetDiagnosticsArgs): Promise<boolean> => false,
  rebootModem: (): any => undefined,
});

/* When a reboot is initiated this monitors the status of the modem as it goes through the reboot process */
const useModemRebootState = (): [
  RebootStates,
  (rebootState: RebootStates) => void
] => {
  const [rebootState, setRebootState] = useState<RebootStates>(
    RebootStates.NOT_ISSUED
  );

  const modemStatusQuery = useQuery<Query>(GET_MODEM_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: { refetchData: true },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    const modemIsOnline = modemStatusQuery.data?.getModemStatus?.isOnline;
    if (modemStatusQuery.loading || !modemStatusQuery.data?.getModemStatus)
      return () => {
        // empty to keep consistent return value
      };

    let timeout: NodeJS.Timeout;
    const refetchModemStatusAfterTenSeconds = () =>
      setTimeout((): any => modemStatusQuery.refetch(), 10 * 1000);

    // If it's supposed to go offline...
    // Check if it's offline and if not check again in 10s
    if (rebootState === RebootStates.PENDING_OFFLINE) {
      if (modemIsOnline) {
        timeout = refetchModemStatusAfterTenSeconds();
      } else {
        setRebootState(RebootStates.PENDING_ONLINE);
      }
    }

    // If it's supposed to go online...
    // Check if it is online and if not check again in 10s
    if (rebootState === RebootStates.PENDING_ONLINE) {
      if (modemIsOnline) {
        setRebootState(RebootStates.SUCCEEDED);
      } else {
        timeout = refetchModemStatusAfterTenSeconds();
      }
    }

    return (): void => {
      if (timeout) clearTimeout(timeout);
    };
  }, [rebootState, modemStatusQuery]);

  return [rebootState, setRebootState];
};

/**
 * Wrap the troubleshooting query to only allow up to 3 refreshes every 15 minutes
 */
const MAX_REFRESHES_WITHIN_TIME_INTERVAL = 3;
const REFRESH_TIME_INTERVAL_MINUTES = 15;
const useTroubleshooting = (): {
  data: { troubleshooting: TroubleshootingInfo } | undefined;
  loading: boolean;
  error: ApolloError | undefined;
  refetch: (args?: QueryGetDiagnosticsArgs) => Promise<boolean>;
} => {
  const { user } = useSelector(selectUser);
  const isAccountActive =
    user.productInstanceStatus === ProductInstanceStatus.Active;
  const {
    data,
    loading,
    error,
    refetch: refresh,
  } = useQuery<any, QueryGetDiagnosticsArgs & { isAccountActive: boolean }>(
    GET_TROUBLESHOOTING,
    {
      variables: { isAccountActive },
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'network-only',
    }
  );

  const logger = errorLogger();
  const { t } = useTranslation('Fix');
  const [allowRefresh, setAllowRefresh] = useState(true);
  const numRefreshes = useRef(0);

  useEffect(() => {
    setAllowRefresh(numRefreshes.current < MAX_REFRESHES_WITHIN_TIME_INTERVAL);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [numRefreshes.current]);

  const resetNumRefreshesTimeout = useRef<NodeJS.Timeout | null>(null);
  useEffect(() => {
    if (allowRefresh)
      return () => {
        // Empty to allow consistent return value
      };

    resetNumRefreshesTimeout.current = setTimeout(
      () => (numRefreshes.current = 0),
      REFRESH_TIME_INTERVAL_MINUTES * 60 * 1000
    );

    return () => {
      if (resetNumRefreshesTimeout.current)
        clearTimeout(resetNumRefreshesTimeout.current);
    };
  }, [allowRefresh]);

  const refetch = async (args?: QueryGetDiagnosticsArgs): Promise<boolean> => {
    if (!allowRefresh) {
      const title = t('TooManyRefreshesTitle');
      const description = t('TooManyRefreshes', {
        amount: MAX_REFRESHES_WITHIN_TIME_INTERVAL,
        numMinutes: REFRESH_TIME_INTERVAL_MINUTES,
      });

      if (Platform.OS === 'web') {
        alert(`${title}\n${description}`);
      } else {
        Alert.alert(title, description);
      }

      return false;
    }

    numRefreshes.current += 1;

    try {
      await refresh({ ...args, isAccountActive, doRefresh: true });
      return true;
    } catch (e: any) {
      logger(e);
      return false;
    }
  };

  return { data, loading, error, refetch };
};

export const TroubleshootProvider = ({
  children,
}: {
  children: any;
}): JSX.Element => {
  const { data, loading, error, refetch } = useTroubleshooting();
  const [rebootModem] = useMutation<Mutation>(REBOOT_MODEM);
  const [rebootState, setRebootState] = useModemRebootState();

  return (
    <Context.Provider
      value={{
        data,
        loading,
        error: Boolean(error),
        rebootState,
        setRebootState,
        refetch,
        rebootModem,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useTroubleshootContext = () => useContext(Context);
