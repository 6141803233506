import { AssessmentName } from '@viasat/res-apps-lib/build/types/mv';

export enum HealthStatus {
  Pass = 'Pass',
  Fail = 'Fail',
}

export enum HealthCheckType {
  Home = 'Home',
  Network = 'Network',
  Account = 'Account',
}

export interface HealthCheck {
  healthStatus: HealthStatus;
  type: HealthCheckType;
  fix: FixStartScreen;
}

export interface HealthChecks {
  [AssessmentName.ServiceConfiguration]: HealthCheck;
  [AssessmentName.ViasatNetwork]: HealthCheck;
  // This health check has been disabled
  // [AssessmentName.CurrentWeatherStatus]: HealthCheck;
  [AssessmentName.DishAlignment]: HealthCheck;
  [AssessmentName.DishToModemConnection]: HealthCheck;
  [AssessmentName.WifiModem]: HealthCheck;
  [AssessmentName.Billing]: HealthCheck;
  [AssessmentName.DataUsage]: HealthCheck;
}

// All the possible UI views we have for all the fixes
export enum FixScreens {
  Configuration = 'Configuration',
  Network = 'Network',
  Dish = 'Dish',
  SoftReboot = 'SoftReboot',
  SoftRebootCountdown = 'SoftRebootCountdown',
  SoftRebootConnection = 'SoftRebootConnection',
  HardRebootSpock = 'HardRebootSpock',
  HardRebootSB2 = 'HardRebootSB2',
  HardRebootConnection = 'HardRebootConnection',
  HardRebootConnectionNoTimer = 'HardRebootConnectionNoTimer',
  HardRebootFailure = 'HardRebootFailure',
  RebootSuccess = 'RebootSuccess',
}

// The start screen for a certain fix, all other fix screens
// are accessible by starting from these screens
export type FixStartScreen =
  | FixScreens.Configuration
  | FixScreens.Network
  | FixScreens.Dish
  | FixScreens.HardRebootSpock
  | FixScreens.HardRebootSB2
  | FixScreens.Network
  | FixScreens.SoftReboot;

export interface TroubleshootingInfo {
  __typename?: 'Troubleshooting';
  lastRan: string | null;
  healthChecks: HealthChecks;
  houseOK: boolean;
  networkOK: boolean;
  errorToFix: AssessmentName | null;
}
