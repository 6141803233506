import React, { useState, useEffect } from 'react';
import { StyleSheet, Platform, View } from 'react-native';
import StorageManager from '../services/localStorage';
import {
  GrayContainer,
  Text,
  Button,
  useNavigate,
  Card,
  useDimensionContext,
  Color,
} from '@mfe/legacy/andromeda';
import { useTranslation } from 'react-i18next';
import { errorLogger } from '../containers';
import { NewCardTitle as CardTitle } from './Card';
import { StackLink, HelpLink } from '../utils/Navigation';
import { urls } from '../configs/url';
import { commonStyles } from '../views/utils';
import { useAccountStatus } from '../utils';
import { useDetectBrowser } from '@mfe/shared/util';
import packageJson from '../myversion.json';

import TriangleErrorExclamation from '../img/svgs/TriangleErrorExclamation';

const styles = StyleSheet.create({
  button: {
    marginTop: 32,
    width: 140,
    height: 40,
    alignSelf: 'flex-start',
    marginLeft: 0,
  },
  errorCardContent: {
    display: 'flex',
    flexDirection: 'row',
  },
  link: {
    color: Color.blue700,
    height: 40,
    paddingTop: 9,
  },
  alignWeb: {
    alignSelf: 'center',
  },
  alignRightWeb: {
    marginLeft: 'auto',
    marginTop: -8,
  },
});

interface ErrorCardProps {
  title?: string;
  retry?: () => void;
  testID?: string | undefined;
}

const MainCardError = ({
  title,
  retry,
  testID,
}: ErrorCardProps): JSX.Element => {
  const { t } = useTranslation(['MainCardLoadingAndError', 'Global']);
  const [type, setType] = useState('error');
  const goTo = useNavigate();
  const browser = useDetectBrowser();
  const { isMobile } = useDimensionContext();
  const { accountReference } = useAccountStatus();
  const logger = errorLogger();
  const REFETCHING_TYPE = 'REFETCHING_TYPE';
  const appVersion = packageJson.version;

  const addType = async (): Promise<void> => {
    try {
      await StorageManager.set(REFETCHING_TYPE, 'chat');
    } catch (e: any) {
      logger(new Error(e));
    }
  };

  const getType = async (): Promise<void> => {
    try {
      const value = await StorageManager.get(REFETCHING_TYPE);
      if (value) {
        setType(value);
      } else {
        setType('error');
      }
      StorageManager.delete(REFETCHING_TYPE);
    } catch (e: any) {
      logger(new Error(e));
    }
  };

  useEffect(() => {
    getType();
  }, []);

  const feedback = (): void => {
    if (Platform.OS === 'web') {
      window.open(
        `${urls.feedbackUrl}?accountReference=${accountReference}&browser=${browser}&screenUser=FeedBack&appVersion=${appVersion}`,
        '_blank'
      );
      return;
    } else {
      goTo('Feedback', {
        append: true,
      });
    }
  };

  const body = (
    <>
      <View style={styles.errorCardContent}>
        <View style={styles.alignWeb}>
          <Text large light>
            {t(`${type}Title`)}
          </Text>
        </View>
        <View style={[styles.alignWeb, styles.alignRightWeb]}>
          <TriangleErrorExclamation />
        </View>
      </View>
      <View
        style={{
          maxWidth: isMobile ? '100%' : 369,
          marginTop: isMobile ? 20 : '',
        }}
      >
        <Text small={!isMobile}>{t(`${type}Text`)}</Text>
      </View>
    </>
  );

  const handleRetry = (): void => {
    retry && retry();
    addType();
  };

  return (
    <Card
      contentStyle={[
        !isMobile ? commonStyles.cardPadding : commonStyles.cardPaddingSM,
        { paddingTop: 32 },
      ]}
      marginStyle={{ marginBottom: 16 }}
      testID={testID}
    >
      <View style={{ paddingHorizontal: 12 }}>
        <CardTitle
          title={t(`${title}`)}
          style={{ textTransform: 'capitalize' }}
        />
        <Text large light>
          {t('DetailsUnavailable', { dataLabel: t(`${title}`) })}
        </Text>
      </View>
      <GrayContainer
        marginStyle={{ marginBottom: 0 }}
        contentStyle={{
          padding: 32,
          paddingHorizontal: 24,
          paddingBottom: isMobile ? 24 : 32,
        }}
      >
        {body}
        {type === 'error' ? (
          <Button
            secondary
            color={Color.blue700}
            small={isMobile}
            style={styles.button}
            onPress={handleRetry}
          >
            {t('retry')}
          </Button>
        ) : (
          <View style={!isMobile && styles.errorCardContent}>
            <Button
              secondary
              color={Color.blue700}
              small={isMobile}
              style={[styles.button, { width: 156 }]}
              onPress={(): void => {
                if (Platform.OS === 'web') {
                  goTo(HelpLink.AgentChat, { drawer: true });
                } else {
                  goTo(StackLink.LiveChat);
                }
              }}
            >
              {t('chat')}
            </Button>
            <Text
              small
              link
              onPress={feedback}
              style={[
                styles.link,
                {
                  marginLeft: !isMobile ? 32 : 0,
                  alignSelf: !isMobile ? 'flex-end' : 'flex-start',
                  marginTop: isMobile ? 20 : 0,
                },
              ]}
            >
              {t('reportIssue')}
            </Text>
          </View>
        )}
      </GrayContainer>
    </Card>
  );
};

export default MainCardError;
