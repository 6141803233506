import React from 'react';
import { FixNavigatorProvider, useFixNavigator } from './FixNavigatorContext';
import { FixScreenComponents } from './Fixs';

const FixNavigator = (): JSX.Element | null => {
  const { currentFixScreen } = useFixNavigator();
  if (!currentFixScreen) return null;

  const CurrentScreenComponent = FixScreenComponents[currentFixScreen];

  return <CurrentScreenComponent />;
};

export const FixNavigatorWrapper = () => (
  <div style={{ backgroundColor: 'white' }}>
    <FixNavigatorProvider>
      <FixNavigator />
    </FixNavigatorProvider>
  </div>
);
