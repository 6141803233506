import { createAction, createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  MvEnv,
  Locale,
  Platform,
  TokenType,
  ProductInstanceStatus,
} from '@mfe/shared/schema-types';
import { ErrorsObj, RuntimeError } from '@mfe/shared/redux/graphql';

export interface TokenInfo {
  accessToken: string;
  accessTokenExpirationTime: string;
  refreshToken?: string;
  type?: TokenType;
  idToken?: string;
  locale?: Locale;
}

export interface AuthUserState {
  loading: boolean;
  error: ErrorsObj | RuntimeError | null;
  user: {
    accountNumber: string;
    productInstanceId: string | null;
    productTypeId: string | null;
    productInstanceStatus: ProductInstanceStatus | null;
    productKind: string | null;
    isACPSuspended: boolean;
    isSoftSuspended: boolean;
    hasErrorProductInstance: boolean;
    hasCaf: boolean;
    relnId: string;
    partyId: string;
    auth: {
      tokenInfo: TokenInfo;
      isAuthenticated: boolean;
      isAuthMFELoaded: boolean;
      username: string;
      subject?: string | null;
    };
  };
}

export const initialAuthUserState: AuthUserState = {
  loading: true,
  error: null,
  user: {
    accountNumber: '',
    productInstanceId: null,
    productTypeId: null,
    productInstanceStatus: null,
    productKind: null,
    isACPSuspended: false,
    isSoftSuspended: false,
    hasErrorProductInstance: false,
    hasCaf: false,
    partyId: '',
    relnId: '',
    auth: {
      tokenInfo: {
        accessToken: '',
        accessTokenExpirationTime: '',
        locale: Locale.EnUs,
      },
      username: '',
      isAuthenticated: false,
      isAuthMFELoaded: false,
    },
  },
};

export type SendAuthParamsAction = PayloadAction<{
  env: MvEnv;
  platform: Platform;
  mfeEnv: MvEnv;
  locale: Locale;
}>;

export const authUserSlice = createSlice({
  name: 'user',
  initialState: initialAuthUserState,
  reducers: {
    authUser: (state) => state,
    sendAuthParams: (state, { payload }: SendAuthParamsAction) => state,
    loginFromCode: (
      state,
      { payload }: { payload: { code: string; locale?: Locale } }
    ) => state,
    loginFromUrl: (state) => state,
    loginFromCookie: (
      state,
      _: { payload: { stringifiedTokenInfo: string } }
    ) => state,
    startRefreshCountdown: (
      state,
      _: { payload: { stringifiedTokenInfo: string } }
    ) => state,
    setTokenInfo: (state, { payload }: { payload: TokenInfo }) => {
      state.loading = false;
      state.user.auth.tokenInfo = { ...state.user.auth.tokenInfo, ...payload };
    },
    setAuth: (
      state,
      { payload }: { payload: Partial<AuthUserState['user']['auth']> }
    ) => {
      state.loading = false;
      state.user.auth = { ...state.user.auth, ...payload };
    },
    setRegistrationAuth: (
      state,
      { payload }: { payload: Partial<AuthUserState['user']['auth']> }
    ) => {
      state.loading = false;
      state.user.auth = { ...state.user.auth, ...payload };
    },
    setAuthMFELoaded: (
      state,
      { payload }: { payload: { isAuthMFELoaded: boolean } }
    ) => {
      state.user.auth.isAuthMFELoaded = payload.isAuthMFELoaded ?? false;
    },
    setUser: (
      state,
      { payload }: { payload: Partial<AuthUserState['user']> }
    ) => {
      state.loading = false;
      state.user = { ...state.user, ...payload };
      state.error = null;
    },
    setError: (
      state,
      { payload }: { payload: ErrorsObj | RuntimeError | null }
    ) => {
      state.loading = false;
      state.error = payload;
    },
    resetAuthUser: () => initialAuthUserState,
  },
});

export const fetchUser = createAction('user/fetchUser');

export const {
  authUser,
  setUser,
  setTokenInfo,
  resetAuthUser,
  loginFromUrl,
  setAuth,
  loginFromCookie,
  startRefreshCountdown,
  sendAuthParams,
  loginFromCode,
  setAuthMFELoaded,
  setRegistrationAuth,
  setError: setAuthUserError,
} = authUserSlice.actions;

export const selectUser = (state: {
  user: AuthUserState;
}): {
  user: AuthUserState['user'];
  loading: AuthUserState['loading'];
  error: AuthUserState['error'];
} => {
  return {
    user: state.user.user,
    loading: state.user.loading,
    error: state.user.error,
  };
};
